<template>
  <div class="admin-dialog-nav">

    <!--top bar-->
    <div class="top">
      <v-toolbar dark color="gray">
        <v-toolbar-title>Administration</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="align-center">
          <v-btn text @click="$dbUi.visible=false">
          <v-icon >mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>

    <!--menu-->
    <div class="left elevation-5">

      <!--liste records-->
      <v-subheader>Bibliothèque</v-subheader>
      <record-model-type-list/>

      <!--général TODO dynamiser-->
      <!--
      <v-subheader>Général</v-subheader>
      <v-list dense color="transparent">
        <v-list-item @click="$dbUi.displaySettings()" :v-model="true">
          <v-list-item-content>
            <v-list-item-title>
              Menu principal
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon>mdi-page-layout-header</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item @click="$dbUi.displaySettings()" :v-model="true">
          <v-list-item-content>
            <v-list-item-title>
              Footer
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon>mdi-page-layout-footer</v-icon>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item @click="$dbUi.displaySettings()" :v-model="true">
          <v-list-item-content>
            <v-list-item-title>
              Classification des formations
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-icon>mdi-view-list</v-icon>
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
      -->

      <!--paramètres-->
      <div class="bottom">
        <v-divider></v-divider>
        <v-list dense color="transparent">
          <v-list-item @click="$dbUi.displaySettings()" :v-model="true">
            <v-list-item-content>
              <v-list-item-title>
                Paramètres
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-avatar>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </div>



    </div>

  </div>
</template>

<script>
import RecordModelTypeList from "../records/record-type/record-model-type-list";
export default {
name: "admin-dialog-nav",
  components: {RecordModelTypeList}
}
</script>

<style scoped>

</style>