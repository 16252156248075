<template>
<v-col cols="12" sm="4" class="infographie">
  <div class="media ">
    <slot name="media"></slot>
  </div>
  <div class="text">
    <slot></slot>
  </div>

</v-col>
</template>

<script>
export default {
  name: "infographie"
}
</script>

<style lang="less">
.infographie{
  text-align: center;
  position: relative;
  >.media{
    min-height: 4vw;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: calc( var(--line-unit) * 0.5);
    position: relative;
    img{
      display: block;
      width: 100%;
      height: 6vmax;
      //outline: 1px dotted #DDD;
      object-fit: contain;
    }
    .pourcent{
      height: 10vw;
    }
  }
  >.text{
    color: #000;
    padding-top: 0;
    //outline: 1px dotted #DDD;
    p{
      &:last-child{
        margin-bottom: 0;
      }
    }
    a{
      color: inherit;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

}
</style>