<template>
<div class="infographies-list" :class="{'dividers':dividers}">
  <v-row class="justify-center infographies-container">
    <slot></slot>
  </v-row>

</div>
</template>

<script>
export default {
  name: "infographies-list",
  props:{
    dividers:{
      type:Boolean,
      default:true
    }
  }
}
</script>

<style lang="less">
  .infographies-list{
    //min-width: 300px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media(min-width: @screen-sm){
      &.dividers{
        .infographies-container{
          >*{
            border-right: 1px solid #000;
            @media(min-width: @screen-md){
              border-right: 3px solid #000;
            }
            &:last-child{
              border-right: none;
            }
          }
        }
      }
    }
  }
</style>