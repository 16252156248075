import BlockItem from "@/ee09/fields/BlockItem";
import ItemList from "@/ee09/models/ItemList";
import LinkItemInfo from "@/Models/nav/LinkItemInfo";

/**
 * Une liste de notifications
 * @typedef {ItemList} NotificationList
 * @property {LinkItemInfo[]} items La liste des notifications
 */

export default class NotificationsBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="notifications-block";
        /**
         *
         * @type {NotificationList}
         */
        this.notifications=new ItemList(
            function(){
                return new LinkItemInfo("Texte de notification ici");
            }
        )
    }

    mount(data) {
        super.mount(data);
        this.notifications._processData();
    }

}