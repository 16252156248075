import LinkItem from "@/ee09/models/LinkItem";

export default class LinkItemInfo extends LinkItem{
    /**
     * Identique à un LinkItem avec un texte d'information en plus
     * @param {string} texte Le texte de d'information
     * @param {null|PageModel} page Une page du site
     * @param {string} href Un lien en dur, si une page est définie, elle prendra le dessus
     * @param {string} label Un label qui prend le dessus sur celui de la page
     */
    constructor(texte="",page=null,href=null,label=null) {
        super(page,href,label)
        /**
         * Type d' item
         * @type {string}
         */
        this.itemType="LinkItemInfo";
        /**
         * Le texte d'info
         * @type {string}
         */
        this.texte=texte;
    }
}