import BlockItem from "@/ee09/fields/BlockItem";
import LinkItem from "@/ee09/models/LinkItem";
import ImageField from "@/ee09/fields/ImageField";

export default class PushBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="push-block";
        /**
         * Le titre
         * @type {string}
         */
        this._title="";
        /**
         * Le sous titre
         * @type {string}
         */
        this.subtitle="";
        /**
         * Le bouton de lien et du coup la page associée
         * @type {LinkItem}
         */
        this.link=new LinkItem();
        /**
         * Pour choisir un image différente
         * @type {ImageField}
         */
        this.customImage=new ImageField()

        /**
         * Activer ou pas la vidéo liée
         * @type {boolean}
         */
        this.displayVideo=true;
    }

    set title(value){
        this._title=value;
    }
    get title(){
        if(this._title){
            return this._title;
        }
        if(this.link.isOk && this.link.label){
            return this.link.label;
        }
        return "...";
    }

    /**
     * Renvoie le record de page (si il y a lieu)
     * @return {null|PageModel}
     */
    get pageRecord(){
        if(this.link.isOk && !this.link.external){
            return this.link.pageRecord.record;
        }
        return null;
    }
    get image(){
        let im=null;
        if(this.customImage.isOk){
            im=this.customImage;
        }
        if(!im){
            if(this.link.isOk && !this.link.external){
                if(this.link.pageRecord.record.poster.isOk){
                    im=this.link.pageRecord.record.poster;
                }
                if(!im){
                    im=this.link.pageRecord.record.thumbnail;
                }
            }
        }

        return im;
    }

    /**
     * Renvoie le FileField de la vidéo de la page si il y a lieu
     * Il faut que displayVideo doit true
     * @return {null|FileField}
     */
    get video(){
        if(this.displayVideo){
            let p=this.pageRecord
            if(p){
                if(p.videoposter.isOk){
                    return p.videoposter;
                }
            }
        }

        return null;
    }

    /**
     * Pour l'admin, renvoie true si une vidéo est disponible
     * @return {null|*|boolean}
     */
    get hasVideo(){
        let p=this.pageRecord;
        return p && p.videoposter.isOk

    }

}