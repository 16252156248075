import BlockItem from "@/ee09/fields/BlockItem";

export default class PosterBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="poster-block";
        /**
         * Le texte
         * @type {string}
         */
        this.text="";
    }

}