<template>
<div id="admin-page-html-generator" class="elevation-24">
  <div class="v-application--wrap">
    <div>
      <main-app-bar
          html-rendering
          :arbo-items="$layout.data.nav.menu"
          :inscription-item="$layout.mainMenu.getLinkItem('Bouton inscription')"/>
    </div>
    <component
        v-if="$dbUi.pageHtmlToGenerate"
        :is="$dbUi.pageHtmlToGenerate.pageComponentName"
        :record="$dbUi.pageHtmlToGenerate">
    </component>
  </div>
</div>
</template>

<script>
import MainNav from "@/layout/main-nav";
import MainAppBar from "@/layout/main-app-bar";
export default {
  name: "admin-page-html-generator",
  components: {MainAppBar, MainNav}
}
</script>

<style lang="less">
#admin-page-html-generator{
  position: fixed;
  top: 100px;
  right: 30px;
  transform-origin: top right;
  transform: scale(0.15);
  width: 1200px;
  min-height: 100px;
  background-color:#FFF;
  outline: 1px solid black;
}
</style>