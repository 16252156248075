<template>
  <v-footer dark class="bg-gradient-gris-fonce big py-10 iffdec-footer">
    <container-grid-size>
      <notifications-block :block="$layout.footer.getNamedBlock(
          'Notifications',
          'notifications-block'
          )"/>
      <!-- telephone--->
      <ico-txt-txt inline icon="iffdec-telephone"
                   class="h4 justify-center has-href-underline my-line-1">

        <a :href="`tel:${$layout.data.footer.contactTelephone}`" class="u-span">
          Contacter nos équipes par téléphone  :
          <span>{{$layout.footer.getString("Téléphone")}}</span>
        </a>
      </ico-txt-txt>
      <!--boxes formation-->
      <menus-formations class="my-line-2"/>

      <!-- 3 colonnes site map -->

      <div>
        <footer-pages-du-site class="py-line-1"/>
        <v-row class="py-line-1">
          <!-- réseau icones -->
          <v-col cols="12" lg="8" class="px-10">
            <ico-txt-txt inline
                         class="h4 mb-line-1"
                         icon="iffdec-ecole">
              Membre du réseau ICONES
            </ico-txt-txt>
            <reseau-icone/>
          </v-col>
          <!--adresse + réseaux sociaux -->
          <v-col lg="4" class="text-center text-sm-left px-10">
            <adresse-social/>
          </v-col>
      </v-row>
      </div>


      <v-row class="mx-md-n15 has-href-underline" v-if="$layout.isDev && $db.userAdminDev">
        <v-col v-for="type of ['page','post','formation','metier','portrait','projet']"
               :key="type"
               cols="12" sm="6" md="4" class="px-md-15">
            <span
                v-for="page of $db.getListType(type)"
                :key="page.uid"
                class="d-block  my-2">
                  <router-link
                      class="txt-link"
                      :to="`/uid/${page.uid}`">
                    {{ page.name }}</router-link>
            </span>
        </v-col>
      </v-row>

      <footer-todo/>

    </container-grid-size>
  </v-footer>
</template>

<script>
import ContainerGridSize from "@/components/atoms/container-grid-size";
import MenusFormations from "@/components/organisms/menus-formations";
import IcoTxtTxt from "@/components/atoms/ico-txt-txt";
import NotificationsBlock from "@/Models/blocks/links/notifications/notifications-block";
import FooterTodo from "@/layout/footer-todo";
import ReseauIcone from "@/layout/reseau-icone";
import AdresseSocial from "@/layout/adresse-social";
import FooterPagesDuSite from "@/layout/footer-pages-du-site";

export default {
  name: "main-footer",
  components: {
    FooterPagesDuSite,
    AdresseSocial,
    ReseauIcone, FooterTodo, NotificationsBlock, IcoTxtTxt, MenusFormations, ContainerGridSize
  }
}
</script>

<style lang="less">
  .iffdec-footer{
    strike{
      opacity: 0.5;
    }
    .social{

      display: flex;
      --space:32px;
      --size:48px;
      @media(min-width: @screen-md){
        --space:3vw;
        --size:4vw;
      }

      .v-icon{
        --icon-size-big:var(--size);
      }
      >*{
        margin-right: var(--space);

        &:first-child{
          @media(min-width: @screen-md) {
            margin-left: calc(var(--size) * -0.25);
          }
        }
        &:last-child{
          margin-right: 0;
        }
      }


    }

  }
</style>