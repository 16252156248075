import BlockItem from "@/ee09/fields/BlockItem";
import ItemList from "@/ee09/models/ItemList";
import TexteAccordeonItem from "@/Models/blocks/texte/textes-accordeons/TexteAccordeonItem";


/**
 * Liste de textes accordéons
 * @typedef {ItemList} TextesAccordeonsList
 * @property {TexteAccordeonItem[]} items La liste des textes
 */

/**
 * Deux listes de textes accordéon
 */
export default class TextesAccordeonsBlock extends BlockItem{
    /**
     *
     */
    constructor() {
        super();
        this.blockType="textes-accordeons-block";
        /**
         *
         * @type {string}
         */
        this.titre1="";
        /**
         *
         * @type {string}
         */
        this.titre2="";
        /**
         *
         * @type {TextesAccordeonsList}
         */
        this.liste1=new ItemList(
            function(){
                return new TexteAccordeonItem()
            }
        );
        /**
         *
         * @type {TextesAccordeonsList}
         */
        this.liste2=new ItemList(
            function(){
                return new TexteAccordeonItem()
            }
        );

    }

    mount(data) {
        super.mount(data);
        this.liste1._processData();
        this.liste2._processData();
    }

}