
import LinkItem from "@/ee09/models/LinkItem";
import PhotoListBlock from "@/Models/blocks/media/photo-list/PhotoListBlock";

export default class PushPhotosBlock extends PhotoListBlock{
    constructor() {
        super();
        this.blockType="push-photos-block";
        this.link=new LinkItem();
        /**
         * Aligner vers la droite ?
         * @type {boolean}
         */
        this.right=false;
        /**
         * Texte d'accompagnement
         * @type {string}
         */
        this.text="";
    }

    /**
     * Aligner vers la gauche ?
     * @return {boolean}
     */
    get left(){
        return !this.right;
    }

    get title(){
        if(this.link.isOk && !this.link.external){
            return this.link.pageRecord.record.name;
        }
        return "...";
    }

    /**
     * Renvoie la liste des champs image qui sont "isOk"
     * @return {ImageField[]}
     */
    get imagesOk(){
        let r=[];
        for(let item of this.images.items){
            if(item.isOk){
                r.push(item.image);
            }
        }
        return r;
    }

}