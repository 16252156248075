export default class AdminUiDrawer {

    constructor() {
        this.open=false;
        this._focus=null;
    }
    get focus() {
        return this._focus;
    }

    /**
     * Permet d'ouvrir la sidebar sur le block ciblé
     * @param value
     */
    set focus(value) {
        //console.log(window.$dbUi.preferences.wysiwygEnabled)
        if(window.$dbUi.preferences.wysiwygEnabled){
            this.open=true;
            this._focus = value;
            //console.log("focus",value);
            let el=document.getElementById(`edit-${value.uid}`);
            if(el){
                el.scrollIntoView({
                    behavior:"smooth",
                    block: "center",
                    inline: "nearest"
                })
            }
        }

    }
}