import DbRecord from "@/ee09/records/DbRecord";
import isEmail from "validator/lib/isEmail";
const sha512 = require('hash.js/lib/hash/sha/512');

export default class UserModel extends DbRecord{
    constructor() {
        super();
        this.type="user";
        this.email="";
        this.hashedpwd=null;
        //les variables temps sont là pour éviter que l'utilisateur ne se désactive ses propres droits d'admin en cours de route
        this.isadmin=null;
        this.isdev=null;
        this.isadminTemp=null;
        this.isdevTemp=null;

    }

    mount(data){
        super.mount(data);
        this.isadminTemp=data.isadmin;
        this.isdevTemp=data.isdev;

    }

    get clearPwd(){
        return this._clearPwd;
    }
    set clearPwd(value){
        this.hashedpwd=UserModel.hashPwd(value);
    }

    /**
     * Renvoie un pwd sha 512 hashé
     * @param {string} pwd
     * @return {string}
     */
    static hashPwd(pwd){
        return sha512().update(pwd).digest('hex');
    }
    static passwordRules(){
        return [
            v => !!v || 'Saisissez votre mot de passe',
            v => (v && v.length >= 8) || 'Ce mot de passe est trop court',
        ]
    }
    static emailRules(){
        return [
            function(v){
                if(!v){
                    return 'Veuillez saisir votre email'
                }
                return true;
            },
            function(v){
                if(v && !isEmail(v)){
                    return "Cet email n'est pas correct"
                }
                return true;
            },
        ]
    }


}