<template>
  <v-row>
    <v-col cols="12" sm="6" md="8" lg="12">
      <!-- adresse -->
      <address class="txt-annotation"
               v-html="$layout.footer.getStringHtml('Adresse')"
      />
    </v-col>
    <v-col>
      <!-- social -->
      <div class="social justify-center justify-sm-start">
        <btn-icon
            v-for="it of social"
            :key="it.uid"
            :href="it.href"
            target="_blank">
          iffdec-{{it.label}}
        </btn-icon>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import BtnIcon from "@/components/atoms/btn-icon";
export default {
  name: "adresse-social",
  components: {BtnIcon},
  computed:{
    /**
     *
     * @return {LinkItem[]}
     */
    social(){
      let r=[];
      r.push(this.$layout.footer.getLinkItem("Facebook"));
      r.push(this.$layout.footer.getLinkItem("Instagram"));
      r.push(this.$layout.footer.getLinkItem("Twitter"));
      return r;
    }
    }
}
</script>

<style scoped>

</style>