<template>
  <a :is="linkItem.component"
     :to="linkItem.to"
     :href="linkItem.toOrHref"
     :target="linkItem.target"
  >
      <span>
        {{ linkItem.label }}
      </span>
  </a>
</template>

<script>
export default {
  name: "link-item-href",
  props:{
    linkItem:{
      type:Object
    }
  }
}
</script>
