import EE09Item from "./EE09Item";
import ImageField from "@/ee09/fields/ImageField";

export default class ImageItem extends EE09Item{
    constructor(imageField) {
        super();
        this.itemType="ImageItem"
        /**
         * L'image
         * @type {ImageField}
         */
        this.image=new ImageField();
        if(imageField){
           this.image=imageField;
        }
    }

    /**
     * Est ok ou pas ?
     * @return {boolean}
     */
    get isOk(){
        return this.image.isOk;
    }
}