import BlockItem from "@/ee09/fields/BlockItem";
import ItemList from "@/ee09/models/ItemList";
import InfographieItem from "@/Models/blocks/media/infographies/InfographieItem";
import ImageField from "@/ee09/fields/ImageField";

/**
 * Une liste d' infographies
 * @typedef {ItemList} InfographieList
 * @property {InfographieItem[]} items La liste des infographies
 */

/**
 *
 */
export default class InfographiesBlock extends BlockItem{
    /**
     *
     */
    constructor() {
        super();
        this.blockType="infographies-block";
        /**
         * Le titre (ou pas)
         * @type {string}
         */
        this.titre="";
        /**
         * chiffre ou pourcentage ou image
         * @type {string}
         */
        this.infographieType="chiffre";
        /**
         *
         * @type {InfographieList}
         */
        this.infos=new ItemList(
            function(){
                return new InfographieItem(123,50,"Texte ici")
            }
        );

    }

    mount(data) {
        super.mount(data);
        this.infos._processData();
        for(let i of this.infos.items){
            if(!i.image){
                i.image=new ImageField();
            }
        }
    }

}