<template>
<div @contextmenu.ctrl="displayContextMenu">
  <v-app v-if="$layout.isReady"
         v-scroll="$layout.onScroll"
         :wysiwyg="$dbUi.preferences.wysiwygEnabled">

      <main-nav />
      <v-main :class="{'pt-0':$layout.appBarTransparent}">
          <div>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path" />
            </transition>
          </div>
      </v-main>

      <main-footer/>

    <!-- l' admin qui ne s' affiche que si on lui demande -->
    <admin-dialog v-if="$db.userAdmin"/>
    <!-- l' admin drawer qui permet d' éditer des trucs sans quitter la page -->
    <admin-drawer v-if="$db.userAdmin"/>
    <!-- le menu contextuel -->
    <admin-context-menu
        v-if="$db.userAdmin && contextMenu.visible"
        :x="contextMenu.x"
        :y="contextMenu.y"
    />
    <admin-page-html-generator
        v-if="$db.userAdmin && $dbUi.pageHtmlToGenerate"
    />


    <!-- librairie de définitions svg-->
    <svg-lib/>

    <!-- force le pré-chargement de certains modules-->
    <div id="no-prefetch" class="d-none">
      <box-menu></box-menu>
      <poster></poster>
      <blocks></blocks>
      <ico-txt-txt-list>
        <ico-txt-txt/>
      </ico-txt-txt-list>
    </div>

    <!-- affiche si on est en xs, sm, md etc... -->
    <div v-if="$dbUi.preferences.displayGraphicsTools"
         id="debug-screen">
      <span class="d-sm-none">xs</span>
      <span class="d-none d-sm-block d-md-none">sm</span>
      <span class="d-none d-md-block d-lg-none">md</span>
      <span class="d-none d-lg-block d-xl-none">lg</span>
      <span class="d-none d-xl-block">xl</span>
    </div>

  </v-app>

  <!-- ici on est en dehors de #app -->

  <div v-else>
    loading...
  </div>

  <pixel-perfect
      v-if="$dbUi.preferences.displayGraphicsTools"
      :files="[
          $api.serverUrl+'/fs/test/prepa.png',
          $api.serverUrl+'/fs/test/Marysedugois.png',
          ]"></pixel-perfect>
</div>




</template>
<script>
import PixelPerfect from "@/dev/pixel-perfect";
import MainNav from "@/layout/main-nav";
import BoxMenu from "@/components/organisms/box-menu";
import Poster from "@/components/organisms/poster";
import Blocks from "@/Models/blocks/blocks";
import IcoTxtTxtList from "@/components/molecules/infographies-list";
import IcoTxtTxt from "@/components/atoms/ico-txt-txt";
import AdminDialog from "@/ee09/db-ui/admin-dialog";
import AdminContextMenu from "@/ee09/db-ui/admin-context-menu";
import MainFooter from "@/layout/main-footer";
import AdminDrawer from "@/ee09/db-ui/admin-drawer";
import SvgLib from "@/layout/svg-lib";
import AdminPageHtmlGenerator from "@/ee09/db-ui/admin-page-html-generator";


export default {
  name: 'App',
  components: {
    AdminPageHtmlGenerator,
    SvgLib,
    AdminDrawer,
    MainFooter,
    AdminContextMenu,
    AdminDialog, IcoTxtTxt, IcoTxtTxtList, Blocks, Poster, BoxMenu, MainNav, PixelPerfect},
  mounted() {
  },
  watch: {
    '$route' (to) {
      //console.log("change route",to);
      this.$layout.stat.pageView(to.path,to.path);
      switch (to.name){
        case "uid":
          this.$layout.currentUid=to.params.uid;
          break;
      }
    }
  },
  data: () => ({
    showMenu: false,
    contextMenu:{
      x: 0,
      y: 0,
      visible:false
    }

  }),
  methods:{

    displayContextMenu(e){
      e.preventDefault()
      this.contextMenu.visible = false
      this.contextMenu.x = e.clientX
      this.contextMenu.y = e.clientY
      this.$nextTick(() => {
        this.contextMenu.visible = true
      })

    }
  }
}

</script>
<style lang="less">
@import "./style-guide/typography.less";
@import "./style-guide/spacing.less";
@import "./style-guide/colors.less";
@import "./style-guide/gradients.less";
@import "./style-guide/shadow.less";
@import "./components/atoms/has-href-underline.less";
@import "./components/atoms/icons.less";
@import "./components/atoms/mask-invp.less";
@import "icons/iffdec-icons/css/iffdec-codes.css";
@import "icons/iffdec-icons/css/iffdec.css";
@import "~@mdi/font/css/materialdesignicons.min.css";

body,html{
  scroll-behavior: smooth;
}
#app{
  //affichage taille d'écran
  #debug-screen{
    position: fixed;
    z-index: 1000;
    font-size: 10px;
    background-color: #0EDEDD;
    padding: 0 5px;
  }
  //si on est en html et que vue n'est pas lancé
  &[html-render="1"]{
    .main-nav{
      transform: none !important;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .v-main__wrap{
    min-height: 100vh;
    overflow-x: hidden;
  }
}


</style>
