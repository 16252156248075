import BlockItem from "@/ee09/fields/BlockItem";

export default class BlocksField{
    /**
     *
     * @param {String[]} possibleBlocksTypes Le nom des blocks que l'on pourra ajouter dans cette liste de blocks
     */
    constructor(possibleBlocksTypes)
    {
        /**
         * La liste des blocks
         * @type {BlockItem[]}
         */
        this.blockList=[];
        /**
         * Les types de block que l'on peut ajouter ici
         * @type {BlockType[]}
         */
        let library=[];
        for(let menuItem of possibleBlocksTypes){
            if(typeof menuItem==="object"){
                library.push(menuItem);
            }else{
                let bt=window.$db.blocksSettings.getBlockModelType(menuItem);
                if(!bt){
                    console.warn("Le block de type "+menuItem+" ne fait pas partie des possiblités");
                    console.warn("Les blocks possibles sont...",window.$db.blocksSettings.blocksModelsTypes);
                }else{
                    library.push(bt);
                }
            }
        }

        this.getLibrary=function(){
            return library;
        }

        /**
         * Dit si il faut monter les datas automatiquement ou pas
         * A utiliser probablement plus tard
         * @type {boolean}
         */
        //this.shouldMountData=true;
    }
    /**
     * Méthode exécutée juste après que le record soit chargé.
     * Cela a pour effet de transformer les blocks json en objets javascript
     * @private
     */
    _processData(){

            for (let i=0;i< this.blockList.length;i++){
                let block=this.blockList[i];
                if(! block.isInit){
                    let model=window.$db.blocksSettings.getBlockModelType(block.blockType);
                    let blockObj;
                    if(!model){
                        console.warn("impossible de monter ce block",block);
                        blockObj=new BlockItem();
                    }else{
                        blockObj=model.create();
                    }
                    blockObj.mount(block);
                    this.blockList[i]=blockObj;
                }
            }


    }

    /**
     *
     * @param {BlockItem} block
     */
    removeBlock(block){
        this.blockList=this.blockList.filter(bl=> block.uid !== bl.uid);
    }

    /**
     *
     * @param {BlockType|String} type
     * @param {BlockItem|String} position soit peut être 'start' ou 'end' ou un BlockItem et dans ce cas le block sera inséré juste après
     * @return {BlockItem}
     */
    addBlock(type,position=null){
        if(typeof type==="string"){
            type=window.$db.blocksSettings.getBlockModelType(type);
        }
        console.log(`add block ${type.label} "(${type.type})`,typeof position,position);
        let b=type.create();
        switch (true){
            case position==='start':
                this.blockList.unshift(b);
                break;
            case (typeof position === "object"):
                console.log("ok",this._getBlockPosition(position))
                this.blockList.splice(this._getBlockPosition(position)+1, 0, b);
                break;
            default:
                this.blockList.push(b);
        }
        return b;
    }

    _getBlockPosition(block){
        for(let i=0;i<this.blockList.length;i++){
            if(this.blockList[i].uid===block.uid){
                return i;
            }
        }
        return 0;
    }
    moveUp(block){
        let from=this._getBlockPosition(block);
        let to=from-1;
        to=Math.max(0,to);
        this.blockList.splice(to, 0, this.blockList.splice(from, 1)[0]);
    }
    moveDown(block){
        let from=this._getBlockPosition(block);
        let to=from+1;
        to=Math.min(this.blockList.length-1,to);
        this.blockList.splice(to, 0, this.blockList.splice(from, 1)[0]);
    }

    /**
     * Renvoie un block par son "name"
     * Peut le créer au besoin
     * @param {String} blockName
     * @param {String|null} blockTypeToCreateIfNull Type de block à créer si null (ne pas définir si vous ne voulez rien créer)
     * @return {BlockItem}
     */
    getByName(blockName,blockTypeToCreateIfNull=null){
        let r=this.blockList.find(
            item=>item['name'].toLowerCase() === blockName.toLowerCase()
        );
        if(!r){
            if(blockTypeToCreateIfNull){
                r=this.addBlock(blockTypeToCreateIfNull,"end");
                r.name=blockName;
            }
        }
        return r;
    }

}