<template>
<div class="admin-drawer">

  <v-navigation-drawer
      class="elevation-24"
      style="z-index: 1000"
      v-if="$layout.currentPage.uid"
      fixed clipped
      v-model="$dbUi.drawer.open"
      width="500px"
  >
    <!-- nav fixe du drawer -->
    <template slot="prepend">
      <v-app-bar style="z-index: 1" dark>
          <record-list-item
              :record="$layout.currentPage"
              class="pl-0" >
            <template v-slot:action>
              <btns-record-action :record="$layout.currentPage" icons/>
            </template>
          </record-list-item>

        <v-divider vertical />

        <v-btn  icon @click="$dbUi.displayRecord($layout.currentPage);$dbUi.drawer.open=false;">
          <v-icon >mdi-window-maximize</v-icon>
        </v-btn>

        <v-btn  icon @click="$dbUi.drawer.open=false">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
    </template>

    <v-list color="#f0f0f0">
      <!-- formulaire page en cours -->
    <component
        class="pa-5 mb-5"
        :record="$layout.currentPage"
        :is="$layout.currentPage.modelType.editComponent">
    </component>
    </v-list>
    <!--
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/women/85.jpg"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">
            Sandra Adams
          </v-list-item-title>
          <v-list-item-subtitle>sandra_a88@gmail.com</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
        nav
        dense
    >
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>My Files</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Shared with me</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Starred</v-list-item-title>
      </v-list-item>
    </v-list>
    -->
  </v-navigation-drawer>

  <!--bouton en bas -->
  <v-speed-dial
      v-if="$layout.currentPage.uid"
      v-model="fab"
      bottom
      left
      direction="right"
      fixed
      open-on-hover
      transition="slide-y-reverse-transition"
  >
    <template v-slot:activator>
      <!-- Bouton principal ouvre la page en cours -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs" v-on="on"
          v-model="fab"
          color="pink" dark fab
          @click="$dbUi.drawer.open=true;">
          <v-icon v-if="fab">mdi-pencil</v-icon>
          <v-icon v-else
                  :class="{'ee09-admin-blink':$layout.currentPage && $layout.currentPage.meta.modified}"
            >{{$layout.currentPage.modelType.icon}}</v-icon>
          </v-btn>
        </template>
        <span>Éditer la page en cours</span>
      </v-tooltip>
    </template>

    <!-- sous menu -->

    <!-- browser records -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            @click="$dbUi.displayRecords($layout.currentPage.type)">
            <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </template>
      <span>Gérer les données</span>
    </v-tooltip>

    <!-- Déconnexion -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            @click="$api.logout()">
          <v-icon>mdi-account-off</v-icon>
        </v-btn>
      </template>
      <span>Déconnexion</span>
    </v-tooltip>

    <!-- paramètres -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            @click="$dbUi.displaySettings()">
            <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <span>Paramètres & préférences</span>
    </v-tooltip>

    <!-- mode édition (ou pas) -->
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs" v-on="on"
            fab dark small
            :color="$dbUi.preferences.wysiwygEnabled?'success':'grey'"
            @click="$dbUi.preferences.wysiwygEnabled=!$dbUi.preferences.wysiwygEnabled">
            <v-icon>mdi-auto-fix</v-icon>
        </v-btn>
      </template>
      <span>{{$dbUi.preferences.wysiwygEnabled?'Désactiver':'Activer'}} le mode édition</span>
    </v-tooltip>



  </v-speed-dial>



</div>
</template>

<script>
import RecordListItem from "@/ee09/db-ui/records/record-list-item";
import BtnsRecordAction from "@/ee09/db-ui/records/btns/btns-record-action";
export default {
  name: "admin-drawer",
  components: {BtnsRecordAction, RecordListItem},
  data(){
    return{
      fab:false,
      show:false
    }
  }
}
</script>

<style scoped>

</style>