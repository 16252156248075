<template>
  <v-row class="my-10" v-if="$layout.isDev && $db.userAdminDev">
    <!---------------todo--------------------->
    <v-col class="px-md-15" cols="12">
      <h4 class="h2">TODO</h4>
    </v-col>
    <v-col cols="12" md="4" class="px-md-15">
      <h3 class="h4">Admin</h3>
      <ul class="mt-5">
        <li><strike>Upload images</strike></li>
        <li><strike>admin wysiwyg sidebar</strike></li>
        <li><strike>Login / logout</strike></li>
        <li><strike>wysiwyg Active / inactive</strike></li>
        <li><strike>Gérer blocs hors pages (header footer)</strike></li>
        <li>Arborescence pour le menu en haut</li>
      </ul>
    </v-col>
    <v-col cols="12" md="4" class="px-md-15">
      <h3 class="h4">Admin (plus tard)</h3>
      <ul class="mt-5">
        <li>
          <a href="https://dev.to/casualcoder/tiptap-with-vuetify-1lmi"
             target="_blank">
            <strike>Rich text</strike>
          </a>
        </li>
        <li>
          <strike>
          <a href="https://stackoverflow.com/questions/56424201/how-to-set-an-anchor-point-for-css-img-cover"
             target="_blank">
            Crop images
          </a>
          </strike>
        </li>
        <li>édition texte en place</li>
        <li>Empêcher la suppression de certains records</li>
        <li>Empêcher le changement de certains champs (name)</li>
        <li>Droits utilisateurs</li>
      </ul>
    </v-col>
    <v-col cols="12" md="4" class="px-md-15">
      <h3 class="h4">SEO</h3>
      <ul class="mt-5">
        <li>Gestion des pages URL</li>
        <li>Admin pages URL</li>
        <li>Sitemap XML</li>
        <li>Search Console</li>
        <li>Séparer output HTML (nav, main, footer)</li>
        <li>cards et meta twitter / FB</li>
      </ul>
    </v-col>
    <v-col cols="12" md="4" class="px-md-15">
      <h3 class="h4">PHP</h3>
      <ul class="mt-5">
        <li><strike>Image resize / cache / urls</strike></li>
      </ul>
    </v-col>
    <v-col cols="12" md="4" class="px-md-15">
      <h3 class="h4">Blocks</h3>
      <ul class="mt-5">
        <li><strike>Iframe</strike></li>
        <li><strike>Icônes</strike></li>
        <li><strike>Citation</strike></li>
        <li><strike>Pourcentages</strike></li>

        <li><strike>ImageS</strike> + zoom</li>
        <li><strike>Image</strike></li>
        <li><strike>CTA buttons</strike></li>
        <li><strike>Focus sur...</strike></li>
        <li><strike>Liste de formations</strike></li>
        <li><strike>Liens texte genre nuage de tag</strike></li>
        <li>Poster home</li>
        <li><strike>Mise en avant scroll text</strike></li>

      </ul>
    </v-col>
    <v-col cols="12" md="4" class="px-md-15">
      <h3>Composants</h3>
      <ul class="mt-5">
        <li><strike>arrow-btn</strike></li>
        <li><strike>slider genre slick</strike></li>
        <li><strike>Menu principal</strike> dynamique</li>
        <li><strike>footer clean</strike></li>
        <li><strike>page (parente) active dans menu haut</strike></li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "footer-todo"
}
</script>

<style scoped>

</style>