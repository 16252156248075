export default {
    props:{
        title:{
            type:String
        },
        subTitle:{
            type:String
        },
        text:{
            type:String
        },
        notifications:{
            type:Object
        },
        breadcrumb:{
            type:Array
        },
        posterMarge:{
            type:Boolean
        },
        posterHome:{
            type:Boolean
        },
        posterPortrait:{
            type:Object
        },
        image:{
            type:Object
        },
        video:{
            type:Object
        },
        menuFormations:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        posterStyle(){
            switch (true){
                case this.posterMarge:
                    return "poster-marge";
                case this.posterPortrait:
                    return "poster-portrait";
                case this.posterHome:
                    return "home";
                default:
                    return "poster-full-width"
            }
        },
        /**
         *
         * @return {NotificationsBlock}
         */
        notifs(){
            return this.notifications;
        }
    }
}