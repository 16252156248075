<template>
  <div class="pixel-perfect">
    <div v-if="show" class="im" :style="`opacity:${opacity};`">
      <img v-if="toDisplay" :src="toDisplay" alt=""/>
    </div>
    <nav>
      <button @click="prev" v-if="show" >prev</button>
      <button @click="next" v-if="show" >next</button>
      <button @click="incrementAlpha(-10)" v-if="show" >-</button>
      <span v-if="show">{{alpha}}%</span>
      <button @click="incrementAlpha(+10)" v-if="show" >+</button>
      <button @click="toggle">{{toggleLabel}}</button>

    </nav>
  </div>
</template>

<script>
export default {
  name: "pixel-perfect",
  data(){
    return{
      i:0.0,
      show:false,
      alpha:10
    }
  },
  props:{
    files:{
      type:Array
    }
  },
  mounted() {


    //afficher ou pas au départ?
    let show=localStorage.getItem("pixel-perfect-show");
    this.show=show==="true";

    let alpha=show=localStorage.getItem("pixel-perfect-alpha");
    if(alpha){
      this.alpha=Number(alpha);
    }
    if(isNaN(this.alpha)){
      this.alpha=10;
    }

    //va tenter de charger la précédente image affichée
    let saved=localStorage.getItem("pixel-perfect");

    console.log("pixel-perfect",show,saved,alpha);



  },
  methods:{
    toggle(){
      this.show=!this.show;
    },
    incrementAlpha(inc){
      this.alpha+=inc;
      this.alpha=Math.round(this.alpha);
      if(this.alpha>100){
        this.alpha=100;
      }
      if(this.alpha<10){
        this.alpha=10;
      }
      localStorage.setItem("pixel-perfect-alpha",this.alpha);
    },
    next(){
      this.i++;
      if(this.i>=this.files.length){
        this.i=0;
      }
      this.save(this.toDisplay);
    },
    prev(){
      this.i--;
      if(this.i<0){
        this.i=this.files.length-1;
      }
      this.save(this.toDisplay);
    },
    save(src){
      localStorage.setItem("pixel-perfect",src);
    }
  },
  computed:{
    toDisplay(){
      if(this.files.length){
        return this.files[this.i];
      }
      return "";
    },
    toggleLabel(){
      let show=this.show?"hide":"show";
      return show;
    },
    opacity(){
      return this.alpha/100;
    }

  },
  watch:{
    show(){
      localStorage.setItem("pixel-perfect-show",this.show?"true":"false");
    }
  }
}
</script>

<style lang="less">
.pixel-perfect{
  z-index: var(--z-pixel-perfect,1000);
  pointer-events: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  >nav{
    position: fixed;
    right: 10px;
    top:10px;
    cursor: pointer;
    pointer-events: auto;
    display: flex;
    >*{
      background-color: yellow;
      font-family: sans-serif;
      margin: 5px;
      padding: 0 4px;
      user-select: none;
      text-transform: uppercase;
      font-size: 12px;
      outline: 0;
    }
  }

  >.im{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0.1;
    background-color: greenyellow;
    >img{
      position: absolute;
      object-fit: cover;
      object-position: top;
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
    }
  }
}
</style>