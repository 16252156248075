<template>
  <v-menu
      v-model="show"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      tile
      min-width="300"
  >
    <v-list>
      <v-subheader>Éditer la page en cours</v-subheader>
      <record-list-item
          @click.native="$dbUi.displayRecord($layout.currentPage)"
          :record="$layout.currentPage"/>

      <v-divider/>

      <v-subheader>Bibliothèque</v-subheader>
      <record-model-type-list/>

      <v-divider/>

      <v-list-item dense @click="$dbUi.displaySettings()" :v-model="true">
        <v-list-item-content>
          <v-list-item-title>
            Paramètres
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-avatar>
      </v-list-item>

    </v-list>
  </v-menu>
</template>

<script>
import RecordListItem from "@/ee09/db-ui/records/record-list-item";
import RecordModelTypeList from "@/ee09/db-ui/records/record-type/record-model-type-list";
export default {
  name: "admin-context-menu",
  components: {RecordModelTypeList, RecordListItem},
  data(){
    return{
      show:true
    }
  },
  props:{
    x:{
      type:Number
    },
    y:{
      type:Number
    }
  }
}
</script>

<style scoped>

</style>