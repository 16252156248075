<template>
  <v-btn
      v-ripple="false"
      @click="$dbUi.visible=false;$dbUi.drawer.open=false;"
      :to="recPage.href"
      class="ml-2 ee09-admin-no-ripple"
      icon>
    <v-icon>mdi-arrow-top-right </v-icon>
  </v-btn>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";

export default {
  name: "btn-icon-aller-sur-la-page",
  mixins:[RecordMixin],
}
</script>

<style scoped>

</style>