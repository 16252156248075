<template>
  <div class="blocks" v-if="fld && fld.blockList">
    <div v-for="block in fld.blockList" :key="block.uid">
      <component v-if="block.blockModel" :is="block.blockModel.displayComponent"
                 :block="block"
                  @click.native="focusBlock(block)"
                 class="block"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "blocks",
  props:{
    blocksField:{
      type:Object,
      mandatory:true
    }
  },
  computed:{
    /**
     *
     * @return {BlocksField}
     */
    fld(){
      return this.blocksField;
    }
  },
  methods:{
    focusBlock(block){
      console.log("bloc focus",block);
      this.$dbUi.drawer.focus=block;
    }
  }
}
</script>

<style lang="less">
  .blocks{
    >*{
    >.block{
        padding-top:    calc( var(--line-unit) * 0.5 );
        padding-bottom: calc( var(--line-unit) * 0.5 );
      }
    }
  }
  [wysiwyg]{
    .block{
      &:hover{
        outline: 1px dashed pink;
        outline-offset: -2px;
      }
    }
  }
</style>