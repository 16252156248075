const isEqual=require('lodash/isEqual');
export default class RecordMeta{

    /**
     *
     * @param {DbRecord} record
     */
    constructor(record) {
        this.uid=record.uid;
        this._modified=false;
        this.json="";
    }

    /**
     * Renvoie le poids du json
     * @return {number}
     */
    get dataSize(){
        return this.json.length;
    }

    get modified() {
        return this._modified;
    }

    set modified(value) {
        if(value){
            if( !isEqual(
                JSON.parse(this.json),
                JSON.parse(JSON.stringify(window.$db.getByUid(this.uid))))
            ){
                //console.log("old2",this.json);
                //console.log("new2",JSON.stringify(window.$db.getByUid(this.uid)));
                this._modified=true;
                if(window.$dbUi.preferences.autoSaveEnabled){
                    window.$db.storeAll();
                }
            }else{
                this._modified=false;
            }
        }else{
            this._modified = value;
        }

    }

}