import LinkItemInfo from "@/Models/nav/LinkItemInfo";
import LinkItem from "@/ee09/models/LinkItem";
import MenuLinkItems from "@/Models/nav/MenuLinkItems";

export default class LayoutData{
    constructor() {
        /**
         * La liste des formations classées par cycle
         * @type {MenuLinkItems[]}
         */
        this.formations=[];
        /**
         * Les éléments de nav principale
         */
        this.nav={
            /**
             * @type {LinkItem}
             */
            inscription:null,
            /**
             * @type {LinkItem}
             */
            home:null,
            /**
             * @type {LinkItem[]}
             */
            menu:[]
        }
        this.footer={
            /**
             * Les blocks de notif en bleu
             * @type {LinkItemInfo[]}
             */
            pushInfos:[],

        }
    }


    mount(){
        let it;
        let $db=window.$db;




        //--------------formations------------------------

        it=new MenuLinkItems();
        it.title="Mise à niveau";
        it.icon="iffdec-stylo";
        it.items.push(
            new LinkItem($db.getByName('formation','Prépa Design'))
        )
        this.formations.push(it);

        it=new MenuLinkItems();
        it.title="Bachelors";
        it.icon="iffdec-etudiant";
        it.items.push(
            new LinkItem($db.getByName('formation','Design d\'espace'))
        )
        it.items.push(
            new LinkItem($db.getByName('formation','Design Graphique'))
        )
        it.items.push(
            new LinkItem($db.getByName('formation','Illustration / Concept Art'))
        )
        this.formations.push(it);

        it=new MenuLinkItems();
        it.title="Mastères";
        it.icon="iffdec-diplome";
        it.items.push(
            new LinkItem($db.getByName('formation','Architecture d\'intérieur'))
        )
        this.formations.push(it);


        //--------------footer----------------------------

        //push
        it=new LinkItemInfo(
            "Candidater à l'IFFDEC dès maintenant",
            //$db.getByName("page","Inscription"),
            $db.getByName('page','formations'),
            "",
            "Candidature"
            );
        this.footer.pushInfos.push(it);
        it=new LinkItemInfo(
            "Des questions ? Contactez nos équipes",
            $db.getByName("page","contact"),
            "",
            "Contact"
        );
        this.footer.pushInfos.push(it);


        
        
        //----------nav------------------------------------
        
        this.nav.home=new LinkItem($db.getByName('page','Home'));
        this.nav.inscription=new LinkItem($db.getByName('page','Inscription'));

        let p, s;
        p=new LinkItem($db.getByName('page',"L'école"));
        this.nav.menu.push(p);
        s=new LinkItem($db.getByName('page',"À propos"));
        p.children.push(s);
        s=new LinkItem($db.getByName('page',"Le campus"));
        p.children.push(s);
        s=new LinkItem($db.getByName('page',"Admission"));
        p.children.push(s);
        s=new LinkItem($db.getByName('page',"Bourses et financements"));
        p.children.push(s);


        p=new LinkItem($db.getByName('page','formations'));
        this.nav.menu.push(p);
        s=new LinkItem($db.getByName('formation',"Prépa Design"));
        p.children.push(s);
        s=new LinkItem($db.getByName('formation',"Design d'espace"));
        p.children.push(s);
        s=new LinkItem($db.getByName('formation',"Design Graphique"));
        p.children.push(s);
        s=new LinkItem($db.getByName('formation',"Illustration / Concept Art"));
        p.children.push(s);
        s=new LinkItem($db.getByName('formation',"Architecture d'intérieur"));
        p.children.push(s);

        p=new LinkItem($db.getByName('page','métiers'));
        this.nav.menu.push(p);
        p=new LinkItem($db.getByName('page','actualités'));
        this.nav.menu.push(p);

        p=new LinkItem($db.getByName('page','portraits'));
        this.nav.menu.push(p);
        s=new LinkItem($db.getByName('page',"Anciens élèves"));
        p.children.push(s);
        s=new LinkItem($db.getByName('page',"Enseignants"));
        p.children.push(s);

        p=new LinkItem($db.getByName('page','contact'));
        this.nav.menu.push(p);

    }

}