import BlockItem from "@/ee09/fields/BlockItem";

export default class IframeBlock extends BlockItem{

    constructor() {
        super();
        this.blockType="iframe-block";
        /**
         * Ce que l'utilisateur a saisi et dont on déduit le reste
         * @type {string}
         */
        this._userInput="";
        /**
         * Le provider déduit de la saisie utilisateur
         * @type {string}
         */
        this.provider="";
        /**
         * identifiant de la vidéo
         * @type {string}
         */
        this.videoId="";
        /**
         * La source de l'iframe
         * @type {string}
         */
        this.iframeSrc="";
    }
    get userInput() {
        return this._userInput;
    }

    set userInput(value) {
        if(value !== this._userInput){
            this._userInput = value;
            this.iframeSrc=this.inputToIframeSrc();
        }
    }

    /**
     * A partir de l'input utilisateur en déduit la source d'une iframe.
     * @return {string|string|*}
     */
    inputToIframeSrc(){
        console.log("inputToIframeSrc")
        this.provider="";
        this.videoId="";
        let input=this._userInput;
        let extract=window.$db.utils.url.getVideoServiceAndId(input);
        if(extract.service && extract.id){
            if(extract.service==="youtube" || extract.service==="vimeo"){
                this.provider=extract.service;
                this.videoId=extract.id;
            }
        }
        let reg;
        let m;
        if(this.provider===""){
            switch (true){
                case window.$db.utils.url.isValid(input):
                    this.provider="http";
                    this.videoId=input;
                    break;
                case String(input).indexOf('<iframe') > -1:
                    this.provider="iframe";
                     reg=new RegExp('(?<=src=").*?(?=[?"])');
                     m = reg.exec(this._userInput);
                     console.log("m",m);
                    if(m){
                        this.videoId=m[0];
                    }
                    break;
            }
        }


        switch (this.provider){
            case "http":
                return this.videoId;
            case "iframe":
                return this.videoId;
            case "youtube":
                return `https://www.youtube.com/embed/${this.videoId}`;
            case "vimeo":
                return `https://player.vimeo.com/video/${this.videoId}`;
            default:
                return "";
        }

    }

}