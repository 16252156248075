<template>
  <v-row class="menus-formations">
    <v-col
        v-for="it of $layout.data.formations" :key="it.title"
        class="pt-0"
        cols="12" md="4">
      <box-menu
          :icon="it.icon"
          :title="it.title"
          :items="it.items"
      />
    </v-col>

  </v-row>
</template>

<script>
import BoxMenu from "@/components/organisms/box-menu";
export default {
  name: "menus-formations",
  components: {BoxMenu}
}
</script>

<style scoped>

</style>