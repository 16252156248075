<template>
  <div class="poster">
    <v-sheet class="wrap"
             :class="`${posterStyle} ${menuFormations?'menu-formations':''}`"
             dark
             color="#888"
    >

      <ee09-picture class="bg-image"
                    :image-field="image"
                    sm="600"
                    md="900"
                    lg="1200"
                    xl="1600"
                    compression="80"
      />
      <ee09-video class="bg-video"
                  :video-field="video"
      />
      <!-- dans le bandeau -->
      <container-grid-size class="py-0 content">
        <slot/>
      </container-grid-size>
    </v-sheet>
    <!-- après le poster (truc bleu, menu formation, portrait) -->
    <slot name="append"/>
  </div>
</template>

<script>
import Ee09Picture from "@/components/atoms/ee09-picture";
import Ee09Video from "@/components/atoms/ee09-video";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import PosterMixin from "@/components/organisms/poster/PosterMixin";
export default {
  name: "poster-wrapper",
  components: {ContainerGridSize, Ee09Video, Ee09Picture},
  mixins:[PosterMixin]
}
</script>

<style lang="less">
  .poster{
    .wrap{
      position: relative;
      height: 23.3vmax;
      background-color: #EEE;
      display: flex;
      justify-content: center;
      align-items: center;
      &.menu-formations{
        height: calc( 23.3vmax + var(--box-menu-line-height) );
      }
      &.home{
        height: calc( 100vh );
      }
      &.poster-portrait{
        align-items: stretch;
        height: 100%;
      }
      >.bg-image,>.bg-video{
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        filter: brightness(0.6);
      }
      .content{
        z-index: 2;
        position: relative;
      }
      &.poster-marge,&.poster-portrait{
        margin-left: auto;
        margin-right: auto;
        --margin:4px;
        --w: calc(100vw - var(--margin) * 2 );
        margin-top: var(--margin);
        height: calc(var(--w) / 1652 * 463); //ratio de 7 / 2 à peu près
        min-height: 300px;
        max-width: var(--w) ;
        @media(min-width: @screen-sm) {
          --margin:var(--line-unit);
        }
        @media(min-width: @screen-lg){
          --margin: calc( var(--line-unit) * 2 );
        }
      }
    }
  }
</style>