<template>
  <div class="page">
    <poster :title="rec.name"
            :text="posterText"
            :poster-marge="isPosterMarge"
            :poster-home="isPosterHome"
            :menu-formations="posterMenuFormations"
            :image="recPage.poster"
            :video="recPage.videoposter"
            :breadcrumb="recPage.breadcrumb"
            :notifications="recPage.moredata.getByName(
                'posterNotifications','notifications-block'
                )"
    />

    <!--blocks-->
    <blocks :blocks-field="recPage.blocks"/>
  </div>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
import Poster from "@/components/organisms/poster";
import Blocks from "@/Models/blocks/blocks";
import PosterOptionsMixin from "@/Models/edit-shared/PosterOptionsMixin";

export default {
  name: "PagePage",
  components: {
    Blocks,
    Poster
  },
  mixins:[RecordMixin,PosterOptionsMixin],
  computed:{
    isPosterMarge(){
      return this.recPage.extravars.posterDisplay==="poster-marge"
              || !this.recPage.extravars.posterDisplay
    },
    isPosterHome(){
      return this.recPage.extravars.posterDisplay==="poster-home"
    },
    posterMenuFormations(){
      return this.formationsPossible &&
          this.recPage.extravars.posterMenuFormations
          && this.recPage.extravars.posterMenuFormations===true;
    },
    posterText(){
      return this.recPage.extravars.posterText;
    }
  }
}
</script>

<style scoped>

</style>