import Vue from "vue";

//Exemple de modèle personnalisé
//import Video from "@/models/Video";
//import VideoEdit from "@/models/video-edit";

import PhpDb from "../ee09/PhpDb";
import DbUi from "@/ee09/db-ui/DbUi";
import FormationModel from "@/Models/FormationModel";
import PortraitModel from "@/Models/PortraitModel";
import MetierModel from "@/Models/MetierModel";
import ProjetModel from "@/Models/ProjetModel";
import PostModel from "@/Models/PostModel";
import TitreBlock from "@/Models/blocks/texte/TitreBlock";
import ParagraphBlock from "@/Models/blocks/texte/ParagraphBlock";
import IframeBlock from "@/Models/blocks/media/IframeBlock";
import CitationBlock from "@/Models/blocks/texte/CitationBlock";
import ObjectifBlock from "@/Models/blocks/texte/ObjectifBlock";
import MetiersBlock from "@/Models/blocks/links/MetiersBlock";
import FormationCycleBlock from "@/Models/blocks/formations/FormationCycleBlock";
import OpendataModel from "@/Models/OpendataModel";
import CoreStringBlock from "@/Models/core-blocks/CoreStringBlock";
import CoreStringHtmlBlock from "@/Models/core-blocks/CoreStringHtmlBlock";
import CoreLinkItemBlock from "@/Models/core-blocks/CoreLinkItemBlock";
import CarouselPagesBlock from "@/Models/blocks/links/carousel-pages/CarouselPagesBlock";
import PushBlock from "@/Models/blocks/links/push/PushBlock";
import PosterBlock from "@/Models/blocks/en-cours/poster/PosterBlock";
import PhotoListBlock from "@/Models/blocks/media/photo-list/PhotoListBlock";
import NotificationsBlock from "@/Models/blocks/links/notifications/NotificationsBlock";
import MediaBlock from "@/Models/blocks/media/MediaBlock";
import InfographiesBlock from "@/Models/blocks/media/infographies/InfographiesBlock";
import CarouselPhotosBlock from "@/Models/blocks/en-cours/carousel-photos/CarouselPhotosBlock";
import CoreLinkItemListBlock from "@/Models/core-blocks/CoreLinkItemListBlock";
import UserModel from "@/Models/UserModel";
import FileRecord from "@/ee09/records/FileRecord";
import BtnsTextBlock from "@/Models/blocks/links/btns-text/BtnsTextBlock";
import TextesAccordeonsBlock from "@/Models/blocks/texte/textes-accordeons/TextesAccordeonsBlock";
import PushPhotosBlock from "@/Models/blocks/links/push-photos/PushPhotosBlock";

//composants vue en global (les pages)
require("./components-loader");
//import DbUiElectron from "./json-db-ui/DbUiElectron";



/**
 *
 * @type {PhpDb}
 */
window.$db=new PhpDb();
let $db=window.$db;
//langues TODO passer les langues avec le language manager
$db.settings.addLanguage("fr","Français",require("svg-country-flags/svg/fr.svg"));
$db.settings.addLanguage("en","English",require("svg-country-flags/svg/gb.svg"));
$db.settings.addLanguage("es","Español",require("svg-country-flags/svg/es.svg"));

//types de records

//Modèles personnalisés

$db.settings.addModelType(
    "post"
    ,"Article"
    ,"mdi-file-document-edit-outline"
    ,function(){
        return new PostModel();
    }
);
$db.settings.addModelType(
    "projet"
    ,"Projet"
    ,"mdi-android-studio "
    ,function(){
        return new ProjetModel();
    }
);
$db.settings.addModelType(
    "formation"
    ,"Formation"
    ,"mdi-school"
    ,function(){
        return new FormationModel();
    }
);

$db.settings.addModelType(
    "portrait"
    ,"Portrait"
    ,"mdi-card-account-details"
    ,function(){
        return new PortraitModel();
    }
);
$db.settings.addModelType(
    "metier"
    ,"Métier"
    ,"mdi-check-decagram"
    ,function(){
        return new MetierModel();
    }
);
$db.settings.addModelType(
    "opendata"
    ,"Structure"
    ,"mdi-chart-tree"
    ,function(){
        return new OpendataModel();
    }
);
$db.settings.addModelType(
    "filerecord"
    ,"Fichier"
    ,"mdi-file"
    ,function(){
        return new FileRecord();
    }
);
$db.settings.addModelType(
    "user"
    ,"Utilisateur"
    ,"mdi-account"
    ,function(){
        return new UserModel();
    }
);

$db.settings.getModelType("page").uiSettings.order=1;
$db.settings.getModelType("post").uiSettings.order=2;
$db.settings.getModelType("formation").uiSettings.order=3;
$db.settings.getModelType("projet").uiSettings.order=4;
$db.settings.getModelType("metier").uiSettings.order=5;
$db.settings.getModelType("portrait").uiSettings.order=6;
$db.settings.getModelType("opendata").uiSettings.order=10;
$db.settings.getModelType("filerecord").uiSettings.order=11;
$db.settings.getModelType("user").uiSettings.order=20;

/*
$db.blocksSettings.defaultList=[
    //{"label":"Blocs textes"},
    'titre-block',
    'paragraph-block',
    'citation-block',
    'objectif-block',
    {"label":"Médias"},
    'iframe-block',
    {"label":"Liens"},
    'metiers-block',
    {"label":"Formations"},
    'formation-cycle-block'
];
 */

//-----------------core blocks---------------------

$db.blocksSettings.addBlockType(
    "core-string-block",
    "Texte",
    "mdi-format-text",
    function(){
        return new CoreStringBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-string-html-block",
    "Texte enrichi",
    "mdi-format-text",
    function(){
        return new CoreStringHtmlBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-link-item-block",
    "Lien",
    "mdi-format-text",
    function(){
        return new CoreLinkItemBlock();
    }
)
$db.blocksSettings.addBlockType(
    "core-link-item-list-block",
    "Liste de liens",
    "mdi-format-list-bulleted",
    function(){
        return new CoreLinkItemListBlock();
    }
)

//-----------------project blocks-------------------------

$db.blocksSettings.defaultList=[];
$db.blocksSettings.defaultList.push({"label":"Blocs textes"})
$db.blocksSettings.addBlockType(
    "titre-block",
    "Titre",
    "mdi-format-title",
    function(){
        return new TitreBlock();
    }
)
$db.blocksSettings.addBlockType(
    "paragraph-block",
    "Paragraphe",
    "mdi-text",
    function(){
        return new ParagraphBlock();
    }
)
$db.blocksSettings.addBlockType(
    "citation-block",
    "Citation",
    "mdi-format-quote-close",
    function(){
        return new CitationBlock();
    }
);
$db.blocksSettings.addBlockType(
    "objectif-block",
    "Objectif",
    "mdi-check",
    function(){
        return new ObjectifBlock();
    }
);

$db.blocksSettings.addBlockType(
    "btns-text-block",
    "Liste de boutons + texte",
    "mdi-view-list-outline",
    function(){
        return new BtnsTextBlock();
    }
)
$db.blocksSettings.addBlockType(
    "textes-accordeons-block",
    "Textes accordéon",
    "mdi-playlist-plus",
    function(){
        return new TextesAccordeonsBlock();
    }
)

$db.blocksSettings.addBlockType(
    "formation-cycle-block",
    "Titre Cycle (formations)",
    "mdi-clock-time-three-outline",
    function(){
        return new FormationCycleBlock();
    }
)


//---medias----
$db.blocksSettings.defaultList.push({"label":"Médias"});

$db.blocksSettings.addBlockType(
    "media-block",
    //"Photo / Vidéo",
    "Image",
    "mdi-image",
    function(){
        return new MediaBlock();
    }
)

$db.blocksSettings.addBlockType(
    "photo-list-block",
    "Liste de photos",
    "mdi-view-dashboard",
    function(){
        return new PhotoListBlock();
    }
)

$db.blocksSettings.addBlockType(
    "iframe-block",
    "Iframe",
    "mdi-code-tags",
    function(){
        return new IframeBlock();
    }
)

$db.blocksSettings.addBlockType(
    "infographies-block",
    "Infographies",
    //"mdi-chart-arc",
    "mdi-circle-slice-6",
    function(){
        return new InfographiesBlock();
    }
)


//---liens----
$db.blocksSettings.defaultList.push({"label":"Liens"});
$db.blocksSettings.addBlockType(
    "notifications-block",
    "Notifications push",
    "mdi-bell",
    function(){
        return new NotificationsBlock();
    }
)
$db.blocksSettings.addBlockType(
    "carousel-pages-block",
    "Carousel de pages",
    "mdi-view-column",
    function(){
        return new CarouselPagesBlock();
    }
)
$db.blocksSettings.addBlockType(
    "push-block",
    "Push",
    "mdi-arrange-send-backward",
    function(){
        return new PushBlock();
    }
);
$db.blocksSettings.addBlockType(
    "push-photos-block",
    "Push + photos",
    "mdi-view-week",
    function(){
        return new PushPhotosBlock();
    }
);
$db.blocksSettings.addBlockType(
    "metiers-block",
    "Liste de métiers",
    "mdi-tag-multiple",
    function(){
        return new MetiersBlock();
    }
);

$db.blocksSettings.defaultList.push({"label":"En cours"})

$db.blocksSettings.addBlockType(
    "carousel-photos-block",
    "Carousel photos",
    "mdi-clock-time-three-outline",
    function(){
        return new CarouselPhotosBlock();
    }
)




$db.blocksSettings.addBlockType(
    "poster-block",
    "Poster ",
    "mdi-clock-time-three-outline",
    function(){
        return new PosterBlock();
    }
)









/**
 * Rend la BDD disponible et réactive dans tous les composants vue
 * @type {PhpDb}
 */
Vue.prototype.$db = Vue.observable(window.$db);

/**
 * L'admin
 * @type {DbUi}
 */
window.$dbUi=new DbUi();
//donner à l'ui des infos sur l'app
window.$dbUi.mainAppPackage=require("../../package.json");
/**
 * Rend l'admin disponible et réactif dans tous les composants vue
 * @type {DbUi}
 */
Vue.prototype.$dbUi = Vue.observable(window.$dbUi);

//quand tout sera prêt...
//window.$db.start(jsonRecords); //appelé depuis main qd la db est prête
