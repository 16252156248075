<template>
<video v-if="vdo && vdo.isOk"
       v-intersect="intersectLarge"
       class="ee09-video"
        autoplay
        loop
        muted
  >
  <source :src="vdo.href"
          :type="vdo.record.mime"
          v-if="isIntersecting"
  >
</video>
</template>

<script>
import InVpMixin from "@/components/atoms/InVpMixin";

export default {
  name: "ee09-video",
  mixins:[InVpMixin],
  props:{
    videoField:{
      type:Object,
    }
  },
  computed:{

    /**
     *
     * @return {FileField}
     */
    vdo(){
      return this.videoField;
    }
  }
}
</script>

<style lang="less">
.ee09-video{
  object-fit: cover;
  //position: relative;
  source{
    //position: relative;
    //width: 100%;
    //height: 100%;
    //object-fit: cover;
  }
}
</style>