import Vue from 'vue'

//models-------------

import PagePage from "./page-page";
import PostPage from "./post-page";
import MetierPage from "./metier-page";
import PortraitPage from "./portrait-page";
import ProjetPage from "./projet-page";
import FormationPage from "./formation-page";

Vue.component('page-page',PagePage);
Vue.component('post-page',PostPage);
Vue.component('metier-page',MetierPage);
Vue.component('portrait-page',PortraitPage);
Vue.component('projet-page',ProjetPage);
Vue.component('formation-page',FormationPage);

let modelsEdit=[
    "./opendata",
    "./user",
    "./page",
    "./post",
    "./metier",
    "./portrait",
    "./projet",
    "./formation",
    "./filerecord",
]
for(let path of modelsEdit){
    let split=path.split("/");
    let name=split[split.length-1];
    Vue.component(
        name+"-edit",
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
        path+'-edit.vue'
            )
    )
}

// core blocks --------------------------------

let coreBlocksEdit=[
    "./core-blocks/named-block-layout",
    "./core-blocks/core-string-block-edit",
    "./core-blocks/core-string-html-block-edit",
    "./core-blocks/core-link-item-block-edit",
    "./core-blocks/core-link-item-list-block-edit"
];

for(let path of coreBlocksEdit){
    let split=path.split("/");
    let name=split[split.length-1];
    Vue.component(
        name,
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
            path+'.vue'
        )
    )
}

let blocks=[
    "./blocks/texte/titre-block",
    "./blocks/texte/paragraph-block",
    "./blocks/texte/citation-block",
    "./blocks/texte/objectif-block",
    "./blocks/texte/textes-accordeons/textes-accordeons-block",

    "./blocks/media/iframe-block",
    "./blocks/media/media-block",
    "./blocks/media/infographies/infographies-block",
    "./blocks/media/photo-list/photo-list-block",

    "./blocks/links/metiers-block",
    "./blocks/links/push/push-block",
    "./blocks/links/push-photos/push-photos-block",
    "./blocks/links/carousel-pages/carousel-pages-block",
    "./blocks/links/notifications/notifications-block",
    "./blocks/links/btns-text/btns-text-block",

    "./blocks/formations/formation-cycle-block",

    "./blocks/en-cours/carousel-photos/carousel-photos-block",

    "./blocks/en-cours/poster/poster-block",

]

for(let b of blocks){
    let split=b.split("/");
    let name=split[split.length-1];
    Vue.component(
        name+'-edit',
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "edit"  */
            b+'-edit.vue'
        )
    )
    Vue.component(
        name+'',
        () => import(
            /* webpackMode: "lazy-once", webpackChunkName: "blocks"  */
        b+'.vue'
    )
    )
}
