import RecordField from "./RecordField";
//import TranslatedField from "./TranslatedField";


export default class ImageField extends RecordField{

    constructor() {
        super();
        /**
         * Description de l'image en plusieurs langues
         * @type {TranslatedField}
         */
        //this.alt=new TranslatedField();
        this.x=0.5;
        this.y=0.5;
        this.alt="";
        this.title=""

    }

    /**
     * Renvoie le record file relatif
     * @return {FileRecord|null}
     */
    get record(){
        return super.record;
    }
    set record(record){
        if(record && record.isImage){
            this.uid=record.uid;
            //this._processData();
        }else{
            this.uid="";
        }

    }

    /**
     * Renvoie le chemin permettant de charger l'image
     * @return {string}
     */
    get href(){
        let r=this.record;
        if(r){
            return r.href;
        }
        return "";
    }

    get isOk(){
        if(this.record){
            return this.record.isOk;
        }
        return false;
    }

    /**
     * Renvoie l'url d'une image redimensionnée
     * @param {Number} width
     * @param {Number} height
     * @param {'contain'|'cover'} mode
     * @param {'contain'|string} backgroundColor
     * @param {Number} quality 0 to 100
     * @param {'jpg'|'png'|'webp'|null} extension
     * @return {string|null}
     */
    resize(width,height,mode="contain",backgroundColor="transparent",quality=80,extension=null){
        if(!this.record){
            return "";
        }
        return this.record.resize(
            width,
            height,
            mode,
            backgroundColor,
            quality,
            extension
        )
    }




}