var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-drawer"},[(_vm.$layout.currentPage.uid)?_c('v-navigation-drawer',{staticClass:"elevation-24",staticStyle:{"z-index":"1000"},attrs:{"fixed":"","clipped":"","width":"500px"},model:{value:(_vm.$dbUi.drawer.open),callback:function ($$v) {_vm.$set(_vm.$dbUi.drawer, "open", $$v)},expression:"$dbUi.drawer.open"}},[_c('template',{slot:"prepend"},[_c('v-app-bar',{staticStyle:{"z-index":"1"},attrs:{"dark":""}},[_c('record-list-item',{staticClass:"pl-0",attrs:{"record":_vm.$layout.currentPage},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('btns-record-action',{attrs:{"record":_vm.$layout.currentPage,"icons":""}})]},proxy:true}],null,false,2528502795)}),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.$dbUi.displayRecord(_vm.$layout.currentPage);_vm.$dbUi.drawer.open=false;}}},[_c('v-icon',[_vm._v("mdi-window-maximize")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.$dbUi.drawer.open=false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-list',{attrs:{"color":"#f0f0f0"}},[_c(_vm.$layout.currentPage.modelType.editComponent,{tag:"component",staticClass:"pa-5 mb-5",attrs:{"record":_vm.$layout.currentPage}})],1)],2):_vm._e(),(_vm.$layout.currentPage.uid)?_c('v-speed-dial',{attrs:{"bottom":"","left":"","direction":"right","fixed":"","open-on-hover":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"pink","dark":"","fab":""},on:{"click":function($event){_vm.$dbUi.drawer.open=true;}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[(_vm.fab)?_c('v-icon',[_vm._v("mdi-pencil")]):_c('v-icon',{class:{'ee09-admin-blink':_vm.$layout.currentPage && _vm.$layout.currentPage.meta.modified}},[_vm._v(_vm._s(_vm.$layout.currentPage.modelType.icon))])],1)]}}],null,false,1822451080)},[_c('span',[_vm._v("Éditer la page en cours")])])]},proxy:true}],null,false,1994878519),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.$dbUi.displayRecords(_vm.$layout.currentPage.type)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-view-list")])],1)]}}],null,false,100224577)},[_c('span',[_vm._v("Gérer les données")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.$api.logout()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-off")])],1)]}}],null,false,201256656)},[_c('span',[_vm._v("Déconnexion")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.$dbUi.displaySettings()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,false,1381206607)},[_c('span',[_vm._v("Paramètres & préférences")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","small":"","color":_vm.$dbUi.preferences.wysiwygEnabled?'success':'grey'},on:{"click":function($event){_vm.$dbUi.preferences.wysiwygEnabled=!_vm.$dbUi.preferences.wysiwygEnabled}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-auto-fix")])],1)]}}],null,false,3029523331)},[_c('span',[_vm._v(_vm._s(_vm.$dbUi.preferences.wysiwygEnabled?'Désactiver':'Activer')+" le mode édition")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }