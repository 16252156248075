import DbRecord from "@/ee09/records/DbRecord";
import BlocksField from "@/ee09/fields/BlocksField";
import RecordField from "@/ee09/fields/RecordField";
import ImageField from "@/ee09/fields/ImageField";
import FileField from "@/ee09/fields/FileField";

export default class PageModel extends DbRecord{
    constructor() {
        super();
        this.type="page";
        /**
         * Output html de la page
         * @type {string}
         */
        this.html="";
        /**
         * La page parente
         * @type {RecordField}
         */
        this.parent=new RecordField();
        /**
         * Le contenu pour la faire courte
         * @type {BlocksField}
         */
        this.blocks=new BlocksField(window.$db.blocksSettings.defaultList);
        /**
         *
         * @type {ImageField}
         */
        this.thumbnail=new ImageField();
        /**
         *
         * @type {ImageField}
         */
        this.poster=new ImageField();
        /**
         *
         * @type {FileField}
         */
        this.videoposter=new FileField();

        /**
         * Des variables libres (non processée, juste du json)
         * @type {{}}
         */
        this.extravars={}

        /**
         * Les datas...qui sont en fait des blocks nommés
         * @type {BlocksField}
         */
        this.moredata=new BlocksField([
            "core-string-block",
            "core-string-html-block",
            "core-link-item-block"
        ]);

    }

    /**
     * Juste pour tester si le record et donc ses modèles hérités sont une page ou non
     * @readonly
     * @return {boolean}
     */
    get isPage(){
        return true;
    }
    /**
     * Lien vers la page
     * @return {string}
     */
    get href(){
        if(window.htmlVars.fmkHref){
            return "/"+window.htmlVars.fmkHref+"/uid/"+this.uid;
        }
        return "/uid/"+this.uid;
    }

    /**
     * Renvoie l'identifiant de composant (vue) pour afficher cette page
     * @return {string}
     */
    get pageComponentName(){
        return this.type+"-page";
    }

    /**
     * Une thumbnail visible uniquement dans l'admin
     * @return {null|string}
     */
    get adminThumb(){
        if(this.thumbnail.isOk){
            return this.thumbnail.resize(50,50,"contain","transparent",80)
        }
        return null;
    }

    get adminSubtitle(){
        let r=super.adminSubtitle;
        if(this.parent.record){
            r+=" | dans "+this.parent.record.name;
        }
        return r;
    }

    /**
     * Renvoie le fil d'ariane de cette page sous forme de tableau plat
     * Se base sur le champ parent pour obtenir cette donnée
     * @return {PageModel[]}
     */
    get breadcrumb() {
        let r = [];
        /**
         *
         * @type {PageModel|null}
         */
        let papa=this.parent.record;
        while (papa && papa.isPage){
            r.unshift(papa);
            papa=papa.parent.record;
        }
        return r;
    }

    saveHtml(cb=function(){}){
        let me=this;
        window.$dbUi.pageHtmlToGenerate=me;
        setTimeout(function(){
            let html=document.getElementById("admin-page-html-generator").innerHTML;
            me.html=html;
            window.$dbUi.pageHtmlToGenerate=null;
            window.$db.store(me,function(){
                cb();

            })
        },1000); //pour laisser le temps à la page de s'afficher correctement
    }



}