
export default {
    computed:{
        formationsPossible(){
            switch (this.record.extravars.posterDisplay){
                case "poster-full-width":
                case "poster-home":
                    return true;
            }
            return false;
        },
        notificationsPossible(){
            switch (this.record.extravars.posterDisplay){
                case "poster-home":
                    return true;
            }
            return false;
        },
        /**
         *
         * @return {null|BlockItem}
         */
        notificationPoster(){
            if(this.notificationsPossible){
                return this.record.moredata.getByName('posterNotifications')
            }
            return null;
        }
    },


}