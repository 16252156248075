<template>
  <v-row class="has-href-underline txt-annotation">
    <v-col v-for="lien of $layout.footer.getLinkItemList('Réseau ICONE').items"
           :key="lien.uid"
           class="py-1 "
           cols="12" sm="6" md="4">
      <link-item-href
          :link-item="lien"/>

    </v-col>
  </v-row>
</template>

<script>
import LinkItemHref from "@/components/atoms/link-item-href";
export default {
  name: "reseau-icone",
  components: {LinkItemHref}
}
</script>
