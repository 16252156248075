
import PageModel from "@/Models/PageModel";

export default class PortraitModel extends PageModel{
    constructor() {
        super();
        this.type="portrait";
        /**
         * Métier
         * @type {string}
         */
        this.fonction="";
    }

}