<template>
<div class="ico-txt-txt"
     :class="{inline:inline}">

  <v-icon class="icon-size">{{icon}}</v-icon>
  <div v-if="!inline" class="txt-link">{{iconText}}</div>

  <div v-if="text" class="txt-label">{{text}}</div>
  <div v-else class="txt-label"><slot/></div>

</div>
</template>

<script>
export default {
  name: "ico-txt-txt",
  props:{
    inline:{
      type:Boolean
    },
    icon:{
      type:String,
      default:"mdi-home"
    },
    iconText:{
      type:String,
      default:"icon text"
    },
    text:{
      type:String,
      default:""
    }
  },
  computed:{

  }
}
</script>

<style lang="less">
.ico-txt-txt{
  >*{
    //outline: 1px solid red;
    text-align: center;
  }
  padding: 12px;
  @media(min-width: @screen-md){
    padding: 0.7vmax 0.5vmax;
  }
  //color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  .txt-link{
    margin-top: 0.8vmax;
    margin-bottom: 1.2vmax;
  }


  &.inline{
    >*{
      text-align: left;
    }
    padding: 0;
    flex-direction: row;
    align-items: center;
    .txt-label{
      margin-left: 1vw;
      margin-right: 1vw;
      line-height: 1;
    }
  }

  --icon-size:var(--icon-size-big);

  &.inline{
    --icon-size:var(--icon-size-text);
  }




}
</style>