<template>
<div class="poster-content-home">
  <div class="top">
    <v-row
        class="flex-grow-1"
        justify="center">
      <v-col cols="8" sm="6" md="4" lg="3"
      >
        <img
            class="logo"
            :class="{scrolled:$layout.isScrolled}"
            src="../../../style-guide/logo-white.svg"
            :alt="$layout.mainMenu.getLinkItem('Logo').label"
        >
      </v-col>
      <v-col cols="12" class="py-0" />
      <v-col cols="12" />
      <v-col sm="8" md="10" lg="8" xl="6" class="py-0">
        {{text}}
      </v-col>
    </v-row>
  </div>
  <div class="bottom" ref="bottom">
    <notification v-if="notif" :link-item-text="notif"/>
  </div>

</div>
</template>

<script>
import { gsap } from "gsap";
import PosterMixin from "@/components/organisms/poster/PosterMixin";
import Notification from "@/components/molecules/notification";
export default {
  name: "poster-content-home",
  components: {Notification},
  mixins:[PosterMixin],
  data(){
    return{
      tl:null,
      notifIdx:-1
    }
  },
  mounted() {
    let me=this;
    if(this.notifs.notifications.items.length>0){
      let tl = gsap.timeline({repeat: -1, repeatDelay: 0});
      me.tl=tl;
      tl.call(me.nextNotif, null, ">");
      let bt=this.$refs.bottom;
      tl.fromTo(bt,
          {
            duration:0.5,
            y:'50',
            opacity:0
          },
              {
                y:'0',
                opacity:1
              }
      );
      tl.to(bt,
          {
            duration:0.5,
            ease: "back.in(1.7)",
            y:'-50',
            opacity:0
          },
          '+=5'
      );

    }

  },
  beforeDestroy() {
    if(this.tl){
      this.tl.kill();
    }

  },
  methods:{
    nextNotif(){
      this.notifIdx++;
      if(this.notifIdx>=this.notifs.notifications.items.length){
        this.notifIdx=0;
      }
    }
  },
  computed:{
    /**
     *
     * @return {null|LinkItemInfo}
     */
    notif(){
      if(this.notifIdx>-1){
        return this.notifs.notifications.items[this.notifIdx];
      }
      return null;
    }
  }
}
</script>

<style lang="less">
.poster-content-home{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;

  >.top{
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom{
    display: flex;
    align-items: stretch;
    flex-direction: column;
    .notification{
      flex-grow: 1;
    }
  }

  .logo{
    width: 100%;
    opacity: 1;
    transition: all 0.5s;
    transition-delay: 0.5s;
    &.scrolled{
      opacity: 0;
      //transform: translateY(-5vw);
    }
  }

}



</style>