<template>
  <div class="poster-formations">
    <gradient-blue class="pt-line-5"/>
    <container-grid-size>
      <menus-formations/>
    </container-grid-size>
  </div>
</template>

<script>
import GradientBlue from "@/components/atoms/gradient-blue";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import MenusFormations from "@/components/organisms/menus-formations";
export default {
  name: "poster-formations",
  components: {MenusFormations, ContainerGridSize, GradientBlue}
}
</script>

<style lang="less">
  .poster-formations{
    z-index: 3;
    position:relative;
    .gradient-blue{
      width: 100%;
      position: absolute;
    }
    .menus-formations{
      position: relative;
      //pour remonter haut dessus du bleu
      transform: translateY( calc( var(--box-menu-line-height) * -1) );
    }

  }
</style>