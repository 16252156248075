<template>
<div class="box-menu shadow-hard-1">
  <div class="h4">
    <v-icon class="icon-size" style="margin-right: 0.5em;">
      {{icon}}
    </v-icon>
    {{title}}
  </div>
  <template v-for="(item,i) of items">
      <router-link
          v-if="item.to"
          :key="i"
          :to="item.to"
          class="txt-link">
        {{item.label}}
      </router-link>
      <a
          v-else
          :key="i"
          :href="item.href"
          class="txt-link">
        {{item.label}}
      </a>
  </template>
</div>
</template>

<script>
export default {
  name: "box-menu",
  props:{
    title:{
      type:String,
      default:"Menu"
    },
    icon:{
      type:String,
      default:"mdi-home"
    },
    items:{
      type:Array
    }
  }
}
</script>

<style lang="less">
  body{
    --box-menu-line-height:60px;
    @media(min-width: @screen-lg){
      --box-menu-line-height:80px;
    }
    @media(min-width: @screen-xl){
      --box-menu-line-height:4vw;
    }
  }
  .box-menu{
    --icon-size: var(--icon-size-text-sm);
    border-radius: 4px;
    @media(min-width:  @screen-md){
      border-radius: 0.5vw;
    }

    overflow: hidden;
    background-color: var(--color-gris-fonce);
    //background-color: #26282D;
    color: #fff;
    >*{
      color: #fff !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: var(--box-menu-line-height);
      max-height: var(--box-menu-line-height);

      text-decoration: none;
      padding: calc( var(--line-unit) * 0.5 ) calc( var(--line-unit) * 1 );
    }
    .h4{
      padding-left: calc( var(--line-unit) * 2 );
    }
    >a{
      margin-bottom: 1px;
      background-color: rgba(255, 255, 255, 0.1);
      //transition: background-color 0.2s;
      &:hover{
        background-color: rgba(255, 255, 255, 0.2);
      }
      //background-color: var(--color-gris-moyen);
    }
  }
</style>