import Vue from 'vue'
import VueRouter from 'vue-router'
import HtmlVars from "@/dataModels/HtmlVars";

Vue.use(VueRouter)

if(!window.htmlVars){
  //console.log("default HtmlVars")
  window.htmlVars=new HtmlVars();
}

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect:'/uid/page-12',
  },
  {
    path: '*/uid/:uid',
    name: 'uid',
    component: () => import('../views/page-uid.vue')
  },
  {
    path: '*/login',
    name: 'login',
    component: () => import('../views/login')
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  base: "/",
  // eslint-disable-next-line no-unused-vars
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset : { x: 0, y: 100 },
        behavior: 'smooth'
      }
      //Or for Vue 3:
      //return {el: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
