import UserModel from "@/Models/UserModel";
import FilesApi from "@/ee09/FilesApi";
const axios = require('axios').default;


export default class EE09Api{
    constructor(serverUrl="/") {
        this.serverUrl=serverUrl;
        this.apiUrl=this.serverUrl+"/api";
        //this.fileApi=new FilesApi(this);
        this._intervalPing=null;
        this.file=new FilesApi(this);
    }
    _call(action,params,cbSuccess,cbError=function(){}){
        params.tokenstring=this._getTokenString();
        axios.post(`${this.apiUrl}/${action}`,
            params
        )
            .then(function (response) {
                //console.log(response.data);
                if(response.data){
                    if(!response.data.success){
                        console.error('api error',action,response.data);
                        cbError(response.data);
                    }else{
                        if(response.data.user !== undefined){
                            //définit l'utilisateur en cours
                            window.$db.userUid=response.data.user?response.data.user.uid:null;
                        }
                        cbSuccess(response.data);
                    }
                }else{
                    cbError(response.data);
                }
            })
            .catch(function (error) {
                console.error(error);
                cbError(error);
            })
            .then(function () {
                // always executed
            });
    }

    loopPing(intervalSeconds=60){
        let me=this;
        if(this._intervalPing){
            clearInterval(this._intervalPing);
        }
        this._intervalPing=setInterval(function(){
            me.ping();
        },intervalSeconds*1000);
    }

    ping(){
        this._call("ping",{},function(){},function (){});
    }

    /**
     * Définit ou efface le token
     * @param {string|null} tokenString
     * @private
     */
    _setTokenString(tokenString){
        if(!tokenString){
            localStorage.removeItem('ee09token');
        }else{
            localStorage.setItem('ee09token', tokenString);
        }
    }
    /**
     * Renvoie le token
     * @return {string}
     * @private
     */
    _getTokenString(){
        let urlParams = new URLSearchParams(window.location.search);
        let t = urlParams.get('token');
        if(t){
            //enregistre le token en local
            this._setTokenString(t);
            document.location=window.location.href.split('?')[0];
            return ;
        }
        if(!t){
            t=localStorage.getItem('ee09token');
        }
        return t;
    }

    /**
     *
     * @param {String|String[]} type
     * @param cbSuccess
     * @param cbError
     */
    getListRecords(type,cbSuccess, cbError){
        this._call("get/list-records",
            {
                type:type,
            },
            cbSuccess,
            cbError
        );
    }
    setStoreRecord(record,cbSuccess, cbError){
        this._call("set/store-record",
            {
                record:record,
            },
            cbSuccess,
            cbError
        );
    }
    setTrashRecord(record,cbSuccess, cbError){
        this._call("set/trash-record",
            {
                record:record,
            },
            cbSuccess,
            cbError
        );
    }

    /**
     * Pour se connecter
     * Cette méthode si elle réussi va enregistrer un token qui sera ensuite réutilisé sur les prochaines requettes
     * @param {string} email
     * @param {string} cleanPassword Ici on fait passer le mot de pase en clair mais il est transmis hashé
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    login(email,cleanPassword,cbSuccess, cbError){
        let me=this;
        let hashedPwd=UserModel.hashPwd(cleanPassword);
        this._call("login",
            {
                email:email,
                pwd:hashedPwd,
            },
            function(data){
                if(data.body.token){
                    me._setTokenString(data.body.token);
                }
                cbSuccess()
            },
            cbError
        );
    }
    /**
     * Pour se déconnecter
     */
    logout(cbSuccess, cbError){
        let me=this;
        this._call("logout",
            {},
            function(){
                me._setTokenString(null);
                document.location.reload();
                cbSuccess()
            },
            cbError
        );
    }
}