
import PageModel from "@/Models/PageModel";
import DateField from "@/ee09/fields/DateField";

export default class PostModel extends PageModel{
    constructor() {
        super();
        /**
         *
         * @type {string}
         */
        this.type="post";
        /**
         * Date de publication de l'article
         * @type {DateField}
         */
        this.date_published=new DateField();
        /**
         * Titre de niveau 2
         * @type {string}
         */
        this.baseline="";
    }
}