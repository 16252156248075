export default {
    props: {
        /**
         *  @type {BlockItem}
         */
        "block":{
            type:Object,
            default:{}
        },
    },
    computed:{
        /**
         * Le block mais spécialement typé BlockItem pour l'autocomplétions de code
         * @return {BlockItem}
         */
        blc(){
            return this.block;
        }
    }
}