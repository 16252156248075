<template>
<div class="notifications-block">
  <container-grid-size>
    <v-row class="my-line-2 justify-center">
      <v-col cols="12" md="6" v-for="item of blc.notifications.items" :key="item.uid">
        <notification :link-item-text="item"/>
      </v-col>
    </v-row>
  </container-grid-size>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import Notification from "@/components/molecules/notification";

export default {
  name: "notifications-block",
  components: {Notification, ContainerGridSize},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {NotificationsBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.notifications-block{
 user-select: none;
}
</style>