
import TitreBlock from "@/Models/blocks/texte/TitreBlock";

export default class FormationCycleBlock extends TitreBlock{
    constructor() {
        super();
        this.blockType="formation-cycle-block";
        /**
         * La durée (1 an, deux ans etc..)
         * @type {string}
         */
        this.duree="1 an";
        /**
         * Le rythme (1,2,3 fois par semaine ou 10h par mois...)
         * @type {string}
         */
        this.rythme="Temps complet";

    }

    hello() {
        alert("hello je suis un formation cycle ")
    }
}