import Stats from "@/dataModels/Stats";
import HtmlVars from "@/dataModels/HtmlVars";
import LayoutData from "@/LayoutData";
const EventEmitter = require('event-emitter-es6');

export default class Layout extends EventEmitter{


    constructor() {
        super();

        /**
         * Est-ce que la nav mobile est ouverte ?
         * @type {boolean}
         */
        this.mobileNavOpen=false;

        /**
         * Quand true c'est qu'on peut afficher les vues
         * En pratique est true quand la db aura des records
         * @type {boolean}
         */
        this._isReady=false;
        /**
         * Est on en dev (vue js hot reload etc...) ou non
         * @type {boolean}
         */
        this.isDev=process.env.NODE_ENV==="development";

        /**
         * La page en cours
         * @type {PageModel}
         */
        this.currentPage={};
        /**
         * L'uid courrant
         * @type {string}
         */
        this._currentUid="";

        /**
         * Version du projet
         * @type {string}
         */
        this.version=require('../package.json').version;

        /**
         * Afficher ou pas l'admin
         * @type {boolean}
         */
        this.adminVisible=false;

        //définit les HTML vars en fonction de php ou par défaut
        if(!window.htmlVars){
            /**
             *
             * @type {HtmlVars}
             */
            window.htmlVars=new HtmlVars();
            window.htmlVars.fmkHrefAbsolute="http://localhost/github/vue-php";
            window.htmlVars.fmkHref="http://localhost/github/vue-php";
        }
        /**
         * Permet d'enregistrer des stats (google analytics)
         * @type {Stats}
         */
        this.stat=new Stats(window.htmlVars.googleAnalyticsId);
        /**
         * Les données propres au layout
         * @type {LayoutData}
         */
        this.data=new LayoutData();
        /**
         * Les datas du footer
         * @type {OpendataModel}
         */
        this.footer=null;
        /**
         * Les datas du menu en haut
         * @type {OpendataModel}
         */
        this.mainMenu=null;
        /**
         * La page a elle été scrollée u pas
         * @type {boolean}
         */
        this.isScrolled=false;



    }

    /**
     * Methdde appelée quand on scrolle
     */
    onScroll(){
        let me=window.$layout;
        //console.log("scroll",window.scrollY)
        let s=window.scrollY;
        me.isScrolled= s>0 ;
        //console.log(me.isScrolled,me.appBarTransparent)
    }

    /**
     * En fonction du poster on di si on doit afficher la nav transparent ou pas
     * @return {boolean}
     */
    get appBarTransparent(){
        if( this.currentPage &&
            this.currentPage.extravars &&
            this.currentPage.extravars.posterDisplay &&
            this.currentPage.extravars.posterDisplay==="poster-home"){
            return true
        }
        return false;
    }

    get currentUid() {
        return this._currentUid;
    }

    set currentUid(value) {
        this._currentUid = value;
        this.currentPage=window.$db.getByUid(this.currentUid);
        if(this.currentPage){
            document.title=this.currentPage.name; //TODO SEO
        }
    }

    get isReady() {
        return this._isReady;
    }

    set isReady(value) {
        this._isReady = value;
        this.footer=window.$db.getByName("opendata","footer",true);
        this.mainMenu=window.$db.getByName("opendata","Menu principal",true);
        this.data.mount();

    }

}


