<template>
  <div>
    <main-drawer :arbo-items="$layout.data.nav.menu"
                 :inscription-item="$layout.mainMenu.getLinkItem('Bouton inscription')"/>
    <main-app-bar :arbo-items="$layout.data.nav.menu"
                  :inscription-item="$layout.mainMenu.getLinkItem('Bouton inscription')"/>
  </div>
</template>
<script>
import MainDrawer from "@/layout/main-drawer";
import MainAppBar from "@/layout/main-app-bar";
export default {
  name: "main-nav",
  components: {MainAppBar, MainDrawer},
  data: () => ({})
}
</script>
