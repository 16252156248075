<template>
<div>
  <!-- si le record est nouveau -->
  <v-btn v-if="!rec.uid"
         color="success" tile small
         @click="$db.store(rec,function(){});
                 $dbUi.displayRecords(rec.type)"
  >
    Créer
  </v-btn>

  <!-- si le record existe déjà -->

  <v-btn v-if="rec.uid"

         :class="{
              'ml-2':!icons,
              '':icons
         }"
         color="error"
         :tile="!icons"
         :small="!icons"
         :icon="icons"
         @click="$dbUi.trashAskRecord(rec)"
  >
    <v-icon v-if="icons">mdi-trash-can</v-icon>
    <span v-else>Supprimer</span>
  </v-btn>

  <v-btn v-if="rec.uid"
         :class="{
              'ml-2':!icons,
              'ml-n2':icons,
              'ee09-admin-blink':rec.meta.modified
         }"
         :disabled="!rec.meta.modified"
         color="success"
         :tile="!icons"
         :small="!icons"
         :icon="icons"
         @click.stop="$db.store(rec,function(){});"
  >
    <v-icon v-if="icons" :small="!icons">mdi-check-circle</v-icon>
    <span v-else>Enregistrer</span>
  </v-btn>

  <!-- lien vers la page-->
  <btn-icon-aller-sur-la-page
      v-if="recPage.isPage"
      :record="record"
  />




</div>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
import BtnIconAllerSurLaPage from "@/ee09/db-ui/records/btns/btn-icon-aller-sur-la-page";

export default {
  name: "btns-record-action",
  components: {BtnIconAllerSurLaPage},
  mixins:[RecordMixin],
  props:{
    icons:{
      type:Boolean
    }
  }
}
</script>
<style lang="less">

</style>
