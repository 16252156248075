import EE09Item from "@/ee09/models/EE09Item";

export default class TexteAccordeonItem extends EE09Item{
    /**
     *
     */
    constructor() {
        super();
        /**
         * Titre / question
         */
        this.titre="";
        /**
         * Texte / réponse html sous le titre
         */
        this.texte="";

    }

    get disabled(){

        let aux = document.createElement('div');
        aux.innerHTML = this.texte; //parses the html
        let trimmedContent = aux.innerText.trim();
        let empty=trimmedContent==="";
        aux=null;
        return empty;
    }
}