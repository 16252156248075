<template>
  <v-row class=" has-href-underline ">
    <v-col v-for="col of ['Liens colonne 1','Liens colonne 2','Liens colonne 3']"
           :key="col"
           class=" py-1 px-10"
           cols="12" sm="6" md="4">
        <link-item-href
            v-for="lien of $layout.footer.getLinkItemList(col).items"
            :link-item="lien"
            :key="lien.uid"
            class="u-span txt-link d-block my-4">

        </link-item-href>
    </v-col>
  </v-row>
</template>

<script>
import LinkItemHref from "@/components/atoms/link-item-href";
export default {
  name: "footer-pages-du-site",
  components: {LinkItemHref}
}
</script>

<style scoped>

</style>