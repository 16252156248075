import DbRecord from "./DbRecord";
//import ImageFactoryUrlNode from "../images/ImageFactoryUrlNode";
export default class FileRecord extends DbRecord{
    constructor() {
        super();
        this.type="filerecord";
        /**
         * Clé de hashage
         * @type {string}
         */
        this.md5="";
        /**
         * Chemin du fichier
         * @type {string}
         */
        this.path="";
        /**
         *
         * @type {string}
         */
        this.href="";
        /**
         * Nous dit si le fichier existe bien
         * @type {null|boolean}
         */
        this.isOk=null;
        /**
         * Mime type du fichier
         * @type {string}
         */
        this.mime=""
        /**
         * Poids du fichiers en bytes
         * @type {number}
         */
        this.bytesize=0;
        /**
         * Largeur si il s'agit d'une image
         * @readonly
         * @type {number}
         */
        this.width=0;
        /**
         * Hauteur si il s'agit d'une image
         * @readonly
         * @type {number}
         */
        this.height=0;



    }

    /**
     * Renvoie l'url d'une image redimensionnée
     * @param {Number} width
     * @param {Number} height
     * @param {'contain'|'cover'} mode
     * @param {'contain'|string} backgroundColor
     * @param {Number} quality 0 to 100
     * @param {'jpg'|'png'|'webp'|null} extension
     * @return {string|null}
     */
    resize(width,height,mode="contain",backgroundColor="transparent",quality=80,extension=null){
        if(this.isImage && this.isOk){
            if(this.extension==="svg"){
                return this.href;
            }
            if(!mode){
                mode="contain";
            }
            if(!backgroundColor){
                backgroundColor="808080";
            }
            if(!extension){
                extension=this.extension;
            }
            if(extension==="jpeg"){
                extension="jpg";
            }

            let base=this.href.replace("fs/up","im/fs/up");
            return `${base}/im-${width}x${height}-${mode}-${backgroundColor}-${quality}.${extension}`;
        }
        return null;
    }

    /**
     * Renvoie l'extension (sans point)
     * @return {string}
     */
    get extension(){
        return window.$db.utils.file.ext(this.href);
    }

    /**
     * Si c'est une image:
     * - Calcule les dimensions de l'image
     * - génère une image 32, 256 et 1024 pour de futures utilisations
     * @private
     */
    _processData(){
        super._processData();
        let me=this;
        //si c'est une image, on fait des mises en cache
        if(this.isImage && window.$db.utils.image){
            if(this.width===0){
                window.$db.utils.image.getSize(this.hrefLocal,
                    function(w,h){
                        me.width=w;
                        me.height=h;
                    }
                );
            }
            /*
            this.resize.thumbnail(32);
            this.resize.thumbnail(256);
            this.resize.thumbnail(1024);
             */

        }
    }

    /**
     * Renvoie true si le fichier est une image
     * @return {RegExpMatchArray}
     */
    get isImage(){
        return this.mime.match(/image/);
    }

    /**
     * Renvoie true si le fichier est une vidéo
     * @return {RegExpMatchArray}
     */
    get isVideo(){
        return this.mime.match(/video/);
    }

    /**
     * Poids et mime du fichier
     * @return {string}
     */
    get adminSubtitle(){
        let r="";
        if(!this.isOk){
            return "Fichier manquant !";
        }
        r+= window.$db.utils.file.humanSize(this.bytesize)+" "+this.mime;
        if(this.isImage){
            r+=` ${this.width}x${this.height}px`
        }
        return r;
    }

    /**
     * Icone qui correspond au type de fichier
     * @return {string}
     */
    get adminSubtitleIcon(){
        //return null;
        return window.$db.utils.file.mdiIcon(this.path,this.mime);
    }

    /**
     * Une thumbnail visible uniquement dans l'admin
     * @return {null|string}
     */
    get adminThumb(){

        if(this.isImage){
            //TODO WEB gérer version alternative
            return this.resize(50,50,"contain","transparent",80)
        }
        return null;
    }

}