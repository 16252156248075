import BlockItem from "@/ee09/fields/BlockItem";
import RecordsField from "@/ee09/fields/RecordsField";
import LinkItem from "@/ee09/models/LinkItem";

export default class MetiersBlock extends BlockItem{

    constructor() {
        super();
        this.blockType="metiers-block";
        /**
         *
         * @type {LinkItem}
         */
        this.link=new LinkItem();

        /**
         * La listes des métiers
         * @type {RecordsField}
         */
        this.records=new RecordsField();

        /**
         * Si true alors on affiche la liste des métiers automatiquement
         * @type {boolean}
         */
        this.automaticList=true;
    }

    /**
     * La liste des métiers qui sera automatique ou manuelle en fonction de la valeur de automaticList
     * @return {MetierModel[]}
     */
    get metiers(){
        if(this.automaticList){
            return window.$db.utils.records.sortByName(
                window.$db.getListType("metier")
            );
        }else{
            return this.records.records;
        }
    }

}