<template>

  <div class="record-table-line mb-1"
       v-bind:class="{ 'open': open,'elevation-5':open, 'pa-5 my-5 mb-10':open }">
      <record-list-item
          :record="record"
          @click="open=!open"
      >
        <template v-slot:action>
          <slot v-if="open" name="action"></slot>
          <!-- lien vers la page-->
          <btn-icon-aller-sur-la-page
              v-if="recPage.isPage && !open"
              :record="record"
          />
          <v-btn icon @click.stop="open=!open" class="ml-4 chevron">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
      </record-list-item>
    <component
        v-if="open"
        class="my-5"
        :record="record"
        :is="$db.settings.getModelType(record.type).editComponent">
    </component>
  </div>

</template>

<script>
import RecordListItem from "./record-list-item";
import BtnIconAllerSurLaPage from "@/ee09/db-ui/records/btns/btn-icon-aller-sur-la-page";
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
export default {
name: "record-table-line",
  components: {BtnIconAllerSurLaPage, RecordListItem},
  mixins:[RecordMixin],
  data(){
    return{
      open:false
    }
  }
}
</script>

<style lang="less">
  .record-table-line{
    background-color:#f0f0f0;
    transition: padding 0.2s, box-shadow 0.5s;
    .chevron{
      transition: transform 0.25s;
    }
    &.open{
      .chevron{
        transform: rotate(180deg);
      }
    }
  }
</style>