<template>
  <a
      class="btn-icon"
      :href="href"
     :target="target">
    <v-icon class="icon-size icon-size-big">
      <slot></slot>
    </v-icon>
  </a>
</template>

<script>
export default {
  name: "btn-icon",
  props:{
    "href":{
      type:String
    },
    "target":{
      type:String
    }
  }
}
</script>

<style lang="less">
.btn-icon{
  color: inherit !important;
  text-decoration: none;
  width: var(--icon-size-big);
  height: var(--icon-size-big);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>