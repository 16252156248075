<template>
  <v-app-bar
      id="main-app-bar"
      dark
      fixed
      color="transparent"
      class="main-nav "
      :app="!htmlRendering"
      elevate-on-scroll
      hide-on-scroll
      :height="height"
      v-resize="onResize"
      :class="{'home-mode':$layout.appBarTransparent && !$layout.isScrolled}"
  >
    <div
        class="bg "
        :class="{
          'elevation-24':backgroundHeight>0,
          'hovered':backgroundHeight>0
        }
        "
        :style="`height:${backgroundHeight}px`">
      <div class="transp"/>
      <div class="filed bg-gradient-gris-fonce"/>
    </div>

    <router-link
        class="logo"
        :to="$layout.mainMenu.getLinkItem('Logo').to"
    >
      <img src="../style-guide/logo-white.svg"
           :alt="$layout.mainMenu.getLinkItem('Logo').label"
      >
    </router-link>

    <v-spacer />

    <!-- le menu -->
    <v-toolbar-items
        class="arbo-menu"
        :class="hiddenDownClass">

      <!-- mover -->
      <gradient-blue class="mover big" ref="mover"/>

      <div v-for="p of this.arbos"
           :key="p.uid"
           :ref="p.uid"
           class="menu-1"
           :class="{hover:hovered && hovered.uid===p.uid}"
           @mouseover="rollover(p)"
           @mouseleave="rollout"
      >

        <!-- niveau 1 item-->
        <router-link
            :to="p.to"
            :ref="p.uid"
            text
            class="txt-link menu-toggler">
          {{p.label}}
        </router-link>

        <!-- sous menu -->
        <div class="menu-2 has-href-underline">
          <!-- niveau 2 item -->
          <div  v-for="sub of p.children"
                :key="'sub'+sub.uid"
                class="item">
            <router-link
                class="u-span txt-link"
                :to="sub.to">
              <span>{{sub.label}}</span>
            </router-link>

          </div>
        </div>
      </div>
    </v-toolbar-items>

    <v-spacer/>

    <!--inscription-->
    <v-btn v-if="inscription"
           :to="inscription.to"
           :href="inscription.external?inscription.href:null"
           :target="inscription.target"
           class="bg-gradient-blue txt-link"

           x-large>
      {{ inscription.label}}
    </v-btn>

    <!--toggle-->
    <v-app-bar-nav-icon

        class="ml-2"
        :class="hiddenUpClass"
        @click="$layout.mobileNavOpen = !$layout.mobileNavOpen"/>


  </v-app-bar>
</template>

<script>
import MainNavMixin from "@/layout/MainNavMixin";
import GradientBlue from "@/components/atoms/gradient-blue";

export default {
  name: "main-app-bar",
  components: {GradientBlue},
  mixins:[MainNavMixin],
  props: {
    htmlRendering:
        {
          type: Boolean,
          default: false
        }
  },
  data(){
    return{
      windowSize:{x:800,y:600},
      /**
       *
       * @type {LinkItem}
       */
      hovered:null,
      hoveredOpen:null,
    }
  },
  mounted() {
    this.onResize();
  },
  watch: {
    //changement de route donc nav doit réagir
    $route() {
      this.hovered=null;
      this.rollout();
      let me=this;
      this.$nextTick(() => {
        this.hovered=null;
        me.rollout();
      })
    }
  },
  methods: {
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    /**
     *
     * @param {LinkItem} p
     */
    rollover(p){
      this.hovered=p;
      this.moveTo();
      if(p.children.length){
        this.hoveredOpen=p;
      }else{
        this.hoveredOpen=false;
      }
    },
    rollout(){
      this.hoveredOpen=false;
      this.hovered=false;
      this.moveTo();
    },
    moveTo(){
      let act=this.getTheActiveElement();
      let left,w;
      if(act){
        left=act.offsetLeft;
        w=act.clientWidth;
      }else{
        left=-1000;
      }
      this.$refs["mover"].$el.style.left=''+left+'px';
      this.$refs["mover"].$el.style.width=''+w+'px';

    },
    /**
     * Renvoie l'élément html de menu principal qui est 'active' ou bien qui est en roll-over
     * @return {HTMLElement}
     */
    getTheActiveElement(){
      //on a un roll over
      if(this.hovered){
        return this.$refs[this.hovered.uid][1];
      }
      let active=document.querySelector("#main-app-bar .router-link-active");
      if(active){
        if(active.matches(".menu-1")){
          //la page courrante est un menu principal
          return active;
        }else{
          //la page courrante est un sous élément...on renvoie son menu principal
          return active.closest(".menu-1");
        }

      }
      return null;

    }
  },
  computed:{

    /**
     * Combien doit mesurer le menu sans tenir compte du sous menu (c'est piur le layout vuetify)
     * @return {Number}
     */
    height(){
      if(this.windowSize.x>1024){

        return Math.max(this.windowSize.x / 1920 * 90, 80);
      }
      return 80;
    },
    /**
     * Combien doit mesurer le background
     * @return {Number}
     */
    backgroundHeight(){
      if(!this.hovered){
        return 0;
      }
      let act=this.theActiveElement;
      if(act){
        let lastEl=act.querySelector(".item:last-child");
        if(lastEl){
          return lastEl.getBoundingClientRect().bottom+40;
        }
      }
      return 0;
    },
    /**
     * L'item de menu actuellement actif
     * - Si il y a un roll over c'est lui qui prend le dessus
     * - Sinon il y a une rubrique active c'est elle
     * @return {HTMLElement|null}
     */
    theActiveElement(){
      return  this.getTheActiveElement();
    }
  },
}
</script>

<style lang="less">

.main-nav{
  height: calc( 90 * 0.05vw );
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    transition: all 0.4s;
    height: 0; //modifié en js via bgHeight
    >*{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      transition: all 0.5s;
    }
    .transp{
      background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4682247899159664) 100%);
    }
  }
  .mover{
    position: absolute;
    max-height: 3px !important;
    min-height: 5px !important;
    height: 5px !important;
    transition: left 0.4s, width 0.2s;
    top: calc( 100% - 5px);
    left: -1000px;
  }

  .logo{
    position: relative;
    //outline: 1px solid yellow;
    height: 80%;
    max-width: 100px;
    >img{
      height: 100%;
      width: 100%;
      object-fit: contain;
      transition: opacity 1s;

    }
  }
  @media(min-width: 1000px){
    .v-toolbar__content{
      padding-left: var(--container-page-padding);
      padding-right: var(--container-page-padding);
    }
    .logo{
      //outline: 1px solid yellow;
      height: 3vw;
      width: 6.5vw;
      max-width: 6.5vw;


    }
  }
  .v-toolbar__content{
    max-width: 100vw;
  }

  &.home-mode{
    .logo{
      >img{
        opacity: 0;
      }
    }
    .bg{

      .filed{
        transform: translateY(-100px);
        opacity: 0;
      }
      &.hovered{
        .filed{
          //background: #000;
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
}

.arbo-menu{

  .router-link-active{
    opacity: 0.8;
  }
  .txt-link{
    padding: 1vw;
    white-space: nowrap;
    >*{
      color: #fff;
    }
  }
}


.arbo-menu{
  .menu-1{
    //outline: 1px solid red;
    display: flex;
    align-items: center;

    >.menu-toggler{
      //outline: 1px solid green;
    }
    >.menu-2{
      //outline: 1px solid blue;
    }

    position: relative;
    >.menu-toggler{
    }
    >.menu-2{

      height: 0;
      overflow-y: hidden;
      //background-color: rgba(34, 34, 34, 0.31);
      outline-offset: 4px;
      position: absolute;
      top: 100%;
      padding-top: 20%;
      display: flex;
      flex-direction: column;
      .item{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        --delay:0.1s;
        .u-span{
          transition: transform 0.6s ease-out;
          transform: translateY(3.5vw);
        }
      }

    }
    &.hover{
      >.menu-toggler{
      }
      >.menu-2 {
        height: auto;
        .item{
          .u-span{
            transform: translateY(0);
          }
        }
      }
    }
  }
}

</style>