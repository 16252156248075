<template>
  <div class="page">
    <poster :title="rec.name"
            :image="recPage.poster"
            :video="recPage.videoposter"
            :breadcrumb="recPage.breadcrumb"/>

    <!-- header -->
    <container-grid-size class="mb-line-1">

      <v-row>

        <!--gauche-->
        <v-col cols="12" md="7" offset-md="1" order="2" order-md="1"
               class="order-1">
          <!--titre-->
          <h2 class="h3 my-line-2 ">
            {{ rec.baseline }}
          </h2>
          <!--icones-->
          <infographies-list class="pr-lg-15">

            <infographie>
              <template v-slot:media>
                <v-icon class="icon-size icon-size-big">iffdec-duree</v-icon>
              </template>
              <div class="txt-link mb-2">durée</div>
              <div class="txt-label">{{rec.duree}}</div>
            </infographie>

            <infographie>
              <template v-slot:media>
                <v-icon class="icon-size icon-size-big">iffdec-rythme</v-icon>
              </template>
              <div class="txt-link mb-2">rythme</div>
              <div class="txt-label">{{rec.rythme}}</div>
            </infographie>

          </infographies-list>
        </v-col>

        <!--droite-->
        <!-- box menu TODO ancres de page -->
        <v-col cols="12" md="4"
               order="1" order-md="2"
               class="py-lg-0 ">
          <div class="d-none d-md-block " style="margin-top: calc( var(--box-menu-line-height) * -1 - var(--line-unit))"/>
          <box-menu
              style="z-index: 3;position: relative"
              title="Sommaire"
              icon="iffdec-menu"
              :items="ancres"
              />
        </v-col>
      </v-row>
    </container-grid-size>

    <!--blocks-->
    <blocks :blocks-field="rec.blocks"/>


  </div>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
import Poster from "@/components/organisms/poster";
import InfographiesList from "@/components/molecules/infographies-list";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import BoxMenu from "@/components/organisms/box-menu";
import Blocks from "@/Models/blocks/blocks";
import Infographie from "@/components/molecules/infographie";


export default {
  name: "FormationPage",
  components: {Infographie,  Blocks, BoxMenu, ContainerGridSize, InfographiesList, Poster},
  mixins:[RecordMixin],
  computed:{
    /**
     *
     * @return {FormationModel}
     */
    rec(){
      return this.record
    },
    /**
     *
     * @return {*[]}
     */
    ancres(){
      let r=[];
      for(let bl of this.rec.blocks.blockList){
        if(bl.isAnchor){
          r.push(
              {
                label:bl.anchorName,
                href:"#"+bl.uid
              }
          );
        }
      }
      return r;
    }
  }
}
</script>

<style scoped>

</style>