/**
 * Une liste d'enregistrements dans la base de données
 */
export default class RecordsField{

    constructor() {
        /**
         * Identifiants unique des DbRecord(s)
         * @type {string[]}
         */
        this.uids=[];
        this._makeItUnique();
    }


    /**
     * Dédoublonne les uids
     * @private
     */
    _makeItUnique(){
        this.uids=[...new Set(this.uids)]
    }
    /**
     * Renvoie les records relatifs
     * @return {DbRecord[]}
     */
    get records(){
        return window.$db.getByUids(this.uids);
    }

    /**
     * Définit les records associés
     * @param {DbRecord[]} value
     */
    set records(value){
        if(value.length>0){
            this.uids=window.$db.utils.records.getUidsArray(value);
        }else{
            this.uids=[];
        }
        this._makeItUnique();
    }

    /**
     * Ajoute un record à la fin
     * @param {DbRecord} record
     */
    addRecord(record){
        if(record && record.uid){
            this.uids.push(record.uid);
        }
        this._makeItUnique();
    }
    /**
     * Ajoute un record au début
     * @param {DbRecord} record
     */
    prepend(record){
        if(record && record.uid){
            this.uids.unshift(record.uid);
        }
        this._makeItUnique();
    }
    /**
     * Remonte le record d'un niveau dans la liste
     * @param {DbRecord} record
     */
    up(record){
        this.records=window.$db.utils.records.moveUp(this.records,record)
    }
    /**
     * Descend le record d'un niveau dans la liste
     * @param {DbRecord} record
     */
    down(record){
        this.records=window.$db.utils.records.moveDown(this.records,record)
    }

    /**
     * Supprime le record de la liste
     * @param {DbRecord} record
     */
    remove(record){
        this.records=window.$db.utils.records.remove(this.records,record)
    }

    hello(){
        alert("hello je suis un recordsField")
    }


}