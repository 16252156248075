<template>

  <poster-wrapper
      :image="image"
      :video="video"
      :menu-formations="menuFormations"
      :poster-marge="posterMarge"
      :poster-portrait="posterPortrait"
      :poster-home="posterHome"
  >
    <container-grid-size class="py-0 content">
      <v-row
          v-if="posterPortrait"
          class="fill-height">
          <v-col cols="12"
                 sm="10" offset-sm="1"
                 class="d-flex align-center">
                <breadcrumb v-if="breadcrumb" :pages="breadcrumb"/>
          </v-col>
          <v-col offset="4"
                 sm="6" offset-sm="5"
                 xl="7" offset-xl="5"
                 class="d-flex flex-grow-1 align-end">
            <div>
              <h1 class="h1">{{title}}</h1>
            </div>
          </v-col>
        </v-row>
      <poster-content-home
          v-else-if="posterHome"
          :text="text"
          :notifications="notifications"
      />
      <v-row v-else>
        <v-col :offset-lg="menuFormations?0:1">
          <breadcrumb
              v-if="breadcrumb && breadcrumb.length"
              :pages="breadcrumb"/>
          <div>
            <h1 class="h1">{{title}}</h1>
          </div>
        </v-col>
      </v-row>
    </container-grid-size>

    <template slot="append">
      <poster-formations  v-if="menuFormations"/>
      <container-grid-size v-else
                           class="py-0">
        <!--degradé-->
        <gradient-blue/>
        <v-row v-if="posterPortrait">
          <v-col cols="4"
                 sm="4" offset-sm="1"
                 xl="4" offset-xl="1"
          >
            <div style="position: relative;" class="fill-height">
              <media-rounded-container
                  class="image-portrait grey darken-3">
                <ee09-picture
                    :xs="150"
                    :sm="250"
                    :md="300"
                    :lg="350"
                    :xl="500"
                    :compression="60"
                    format="jpg"
                    class="rounded"
                    style="position: absolute;width: 100%;height: 100%;"
                    :image-field="posterPortrait"/>
              </media-rounded-container>
            </div>

          </v-col>
          <v-col cols="8"
                 sm="6" offset-sm="0">
            <h2 class="h3 mt-line-1">
              {{ subTitle }}
            </h2>
          </v-col>
        </v-row>
      </container-grid-size>
    </template>


  </poster-wrapper>

</template>

<script>
import Breadcrumb from "@/components/organisms/breadcrumb";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import GradientBlue from "@/components/atoms/gradient-blue";
import Ee09Picture from "@/components/atoms/ee09-picture";
import MediaRoundedContainer from "@/components/atoms/media-rounded-container";
import PosterFormations from "@/components/organisms/poster/poster-formations";
import PosterMixin from "@/components/organisms/poster/PosterMixin";
import PosterWrapper from "@/components/organisms/poster/poster-wrapper";
import PosterContentHome from "@/components/organisms/poster/poster-content-home";
export default {
  name: "poster",
  components: {
    PosterContentHome,
    PosterWrapper,
    PosterFormations,
    MediaRoundedContainer,
    Ee09Picture,
    GradientBlue,
    ContainerGridSize,
    Breadcrumb
  },
  mixins:[PosterMixin],


}
</script>

<style lang="less">
  .poster{
    .image-portrait{
      overflow: hidden;
      filter:saturate(0);
      z-index: 3;
      position: absolute;
      height:40vw;
      left: 0;
      right: 0;
      @media(min-width:500px){
        height: 35vw;
      }
      @media(min-width:@screen-sm){
        height: 28vw;
        width: 23vw;
        right: auto;
      }
      @media(min-width:750px){
        width: 17vw;
        right: auto;
        height: 22vw;
      }
      @media(min-width:850px){
        width: 15vw;
        right: auto;
        height: 19vw;
      }
      //width: 100%;
      bottom: 0;

    }
  }
</style>