export default class MenuLinkItems{
    /**
     * Permet de regrouper des LinkItems sous un titre
     */
    constructor() {
        /**
         * Titre du menu
         * @type {string}
         */
        this.title="";
        /**
         * Icone du menu
         * @type {string}
         */
        this.icon="";
        /**
         *
         * @type {LinkItem[]}
         */
        this.items=[];
    }
}