import BlockItem from "@/ee09/fields/BlockItem";

export default class ObjectifBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="objectif-block";
        /**
         * Le texte HTML
         * @type {string}
         */
        this.text="";
    }
}