<template>
  <div class="notification px-5 py-3 shadow-hard-1 bg-gradient-blue
              px-lg-10 py-lg-6">
    <v-row class="align-center">
      <v-col cols="12" sm="7" class="text-center text-sm-left">{{texte}}</v-col>
      <v-col cols="12" sm="5" class="text-center text-sm-right">
        <btn-arrow :link-item="it"/>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import BtnArrow from "@/components/atoms/btn-arrow";
export default {
  name: "notification",
  components: {BtnArrow},
  props:{
    linkItemText:{
      type:Object
    }
  },
  computed:{
    /**
     *
     * @return {LinkItemInfo}
     */
    it(){
      return this.linkItemText;
    },
    texte(){
      return this.it.texte;
    }
  }
}
</script>

<style lang="less">
.notification{
  color: #fff;
  border-radius: 8px;
  //padding: 1vw 1.5vw;
}
</style>