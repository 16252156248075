
import PageModel from "@/Models/PageModel";

export default class FormationModel extends PageModel{
    constructor() {
        super();
        this.type="formation";
        this.baseline="";
        this.duree="";
        this.rythme="";
    }

}