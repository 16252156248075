import BlockItem from "@/ee09/fields/BlockItem";
import ItemList from "@/ee09/models/ItemList";
import LinkItem from "@/ee09/models/LinkItem";

/**
 * Une liste boutons avec un block texte à droite
 * @typedef {ItemList} LinkItemList
 * @property {LinkItem[]} items La liste des boutons
 */

export default class BtnsTextBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="btns-text-block";
        /**
         *
         * @type {LinkItemList}
         */
        this.buttons=new ItemList(
            function(){
                return new LinkItem();
            }
        )
        /**
         * Texte htem affiché à droite
         * @type {string}
         */
        this.text="";
    }

    mount(data) {
        super.mount(data);
        this.buttons._processData();
    }

}