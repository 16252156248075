<template>
  <div class="page">
    <poster :title="rec.name"
            :sub-title="rec.fonction"
            :poster-portrait="recPage.thumbnail"
            :image="recPage.poster"
            :video="recPage.videoposter"
            :breadcrumb="recPage.breadcrumb"
    />

    <!--blocks-->
    <blocks :blocks-field="rec.blocks"/>


  </div>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";
import Poster from "@/components/organisms/poster";
import Blocks from "@/Models/blocks/blocks";


export default {
  name: "PortraitPage",
  components: {   Blocks, Poster},
  mixins:[RecordMixin],
  computed:{
    /**
     *
     * @return {PortraitModel}
     */
    rec(){
      return this.record
    }
  }
}
</script>
