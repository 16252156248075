export default {
    data(){
        return{
            mobileBreakpoint:"lg",
            hiddenDownClass:"hidden-md-and-down",
            hiddenUpClass:"hidden-lg-and-up"
            //hiddenUpClass:"hidden-xl-and-up"
        }
    },
    props:{
        arboItems:{
            type:Array
        },
        inscriptionItem:{
            type:Object
        }
    },
    computed:{
        /**
         *
         * @return {LinkItem[]}
         */
        arbos(){
            return this.arboItems;
        },
        /**
         *
         * @return {LinkItem}
         */
        inscription(){
            return this.inscriptionItem;
        }
    }

}