import BlockItem from "@/ee09/fields/BlockItem";
import ImageField from "@/ee09/fields/ImageField";

export default class MediaBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="media-block";
        /**
         * Le texte
         * @type {string}
         */
        this.text="";

        this.image=new ImageField();

    }

}