<template>
  <v-breadcrumbs :items="items"
                 class="breadcrumb txt-link ml-n5 has-href-underline">
    <template v-slot:divider>
      <v-icon>mdi-chevron-right</v-icon>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: "breadcrumb",
  props:{
    pages:{
      type:Array
    }
  },
  computed:{
    items(){
      let r=[];
      for(let page of this.pages){
        if(page){
          r.push({
            text: page.name,
            disabled: false,
            to: page.href
          })
        }

      }
      //r[r.length-1].disabled=true;
      return r;
    }
  }
}
</script>

<style lang="less">
  .breadcrumb{
    li{
      font-size: inherit !important;
      a{
        color: #fff;
      }
    }


  }
</style>