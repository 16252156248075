import BlockItem from "@/ee09/fields/BlockItem";
import ItemList from "@/ee09/models/ItemList";
import ImageItem from "@/ee09/models/ImageItem";
import ImageField from "@/ee09/fields/ImageField";

/**
 * Une liste d'images
 * @typedef {ItemList} ImageItemList
 * @property {ImageItem[]} items La liste des images
 */


export default class PhotoListBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="photo-list-block";
        /**
         *
         * @type {ImageItemList}
         */
        this.images=new ItemList(
            function(imageField){
                return new ImageItem(imageField);
            }
        );


    }

    mount(data) {
        super.mount(data);
        this.images._processData();
        for(let i of this.images.items){
            if(!i.image){
                i.image=new ImageField();
            }
        }
    }

}