import BlockItem from "@/ee09/fields/BlockItem";
import LinkItem from "@/ee09/models/LinkItem";

export default class TitreBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="titre-block";
        /**
         * Le texte
         * @type {string}
         */
        this.text="";
        /**
         * Balise à utiliser
         * @type {string}
         */
        this.seo="h3";
        /**
         * Classe css à utiliser pour afficher ce titre
         * @type {string}
         */
        this.cssClass="h3";
        /**
         * A un lien a côté ou non?
         * @type {boolean}
         */
        this.hasLink=false;
        /**
         * Ajouter un lien (ou pas)
         * @type {LinkItem}
         */
        this.lien=new LinkItem();
        /**
         * Centrer ou non ?
         * @type {boolean}
         */
        this.center=false;

        /**
         * Afficher la fleche ou non ?
         * @type {boolean}
         */
        this.arrow=true;

    }

    hello() {
        alert("hello je suis un titre")
    }
}