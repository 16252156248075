import EE09Item from "@/ee09/models/EE09Item";
import ImageField from "@/ee09/fields/ImageField";

export default class InfographieItem extends EE09Item{
    /**
     *
     * @param chiffre
     * @param pourcentage
     * @param texte
     */
    constructor(chiffre,pourcentage,texte) {
        super();
        /**
         * Texte html sous le graphique
         */
        this.texte=texte;
        /**
         * Si c'est un chiffre
         */
        this.chiffre=chiffre;
        /**
         * Si c'est un pourcentage
         */
        this.pourcentage=pourcentage;
        /**
         * Si c'est une image
         * @type {ImageField}
         */
        this.image=new ImageField();
    }
}