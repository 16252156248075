<template>
  <v-navigation-drawer
      app
      right dark
      :mobile-breakpoint="mobileBreakpoint"
      v-model="$layout.mobileNavOpen"
      class="elevation-24 bg-gradient-gris-fonce big"
      temporary
      width="300"
      clipped
      disable-resize-watcher

  >
    <v-list>
      <template v-for="p of this.arbos">

        <v-list-item
            :key="p.uid"
            :to="p.to">
          <v-list-item-content>
            <v-list-item-title>
              {{p.label}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-for="sub of p.children"
                     :key="'sub'+sub.uid"
                     :to="sub.to">
          <v-list-item-subtitle>
            {{sub.label}}
          </v-list-item-subtitle>
        </v-list-item>

      </template>

    </v-list>
  </v-navigation-drawer>
</template>

<script>
import MainNavMixin from "@/layout/MainNavMixin";

export default {
  name: "main-drawer",
  mixins:[MainNavMixin]
}
</script>

<style scoped>

</style>