import TitreBlock from "@/Models/blocks/texte/TitreBlock";
import RecordsField from "@/ee09/fields/RecordsField";

export default class CarouselPagesBlock extends TitreBlock{
    constructor() {
        super();
        this.blockType="carousel-pages-block";
        /**
         * Les pages
         * @type {RecordsField}
         */
        this.pages=new RecordsField();
        /**
         * Si true on est en mode grille
         * @type {boolean}
         */
        this.gridMode=false;
        /**
         * N'affiche pas le fond bleu si true
         * @type {boolean}
         */
        this.transparent=false;
    }

}