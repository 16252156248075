var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.rec.uid)?_c('v-btn',{attrs:{"color":"success","tile":"","small":""},on:{"click":function($event){_vm.$db.store(_vm.rec,function(){});
                 _vm.$dbUi.displayRecords(_vm.rec.type)}}},[_vm._v(" Créer ")]):_vm._e(),(_vm.rec.uid)?_c('v-btn',{class:{
              'ml-2':!_vm.icons,
              '':_vm.icons
         },attrs:{"color":"error","tile":!_vm.icons,"small":!_vm.icons,"icon":_vm.icons},on:{"click":function($event){return _vm.$dbUi.trashAskRecord(_vm.rec)}}},[(_vm.icons)?_c('v-icon',[_vm._v("mdi-trash-can")]):_c('span',[_vm._v("Supprimer")])],1):_vm._e(),(_vm.rec.uid)?_c('v-btn',{class:{
              'ml-2':!_vm.icons,
              'ml-n2':_vm.icons,
              'ee09-admin-blink':_vm.rec.meta.modified
         },attrs:{"disabled":!_vm.rec.meta.modified,"color":"success","tile":!_vm.icons,"small":!_vm.icons,"icon":_vm.icons},on:{"click":function($event){$event.stopPropagation();_vm.$db.store(_vm.rec,function(){});}}},[(_vm.icons)?_c('v-icon',{attrs:{"small":!_vm.icons}},[_vm._v("mdi-check-circle")]):_c('span',[_vm._v("Enregistrer")])],1):_vm._e(),(_vm.recPage.isPage)?_c('btn-icon-aller-sur-la-page',{attrs:{"record":_vm.record}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }