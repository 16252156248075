import LinkItem from "@/ee09/models/LinkItem";
import BlockItem from "@/ee09/fields/BlockItem";
import ItemList from "@/ee09/models/ItemList";

/**
 * Une liste de link item
 * @typedef {ItemList} LinkItemList
 * @property {LinkItem[]} items La liste des liens
 */


/**
 * Une liste de link item
 */
export default class CoreLinkItemListBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="core-link-item-list-block";
        /**
         * Le lien
         * @type {LinkItemList}
         */
        this.list=new ItemList(
            function(){
                return new LinkItem();
            }
        );
    }

    mount(data) {
        super.mount(data);
        //console.log("mount CoreLinkItemListBlock",data)
        this.list._processData();
    }

}